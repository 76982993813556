import { Button, Dialog, DialogActions, DialogContent, Paper, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { ArrowLeft } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'

export default function LevelSettings() {
    const history = useHistory()
    const { createLevel, GetLevels } = useDb()
    const [open, setOpen] = useState(false)
    const [levelName, setLevelName] = useState(null)
    const [level, setLevel] = useState(2)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const levels = GetLevels()

    const handleSubmit = async () => {
        setLoading(true)
        try{
            await createLevel({levelName, level})
            setOpen(false)
            setLevelName(null)
            setLevel(2)
            setLoading(false)
        }
        catch(err) {
            setError(err)
            setLoading(false)
        }
    }

    return (
        <div>
            <Button startIcon={<ArrowLeft />} color={'primary'} onClick={() => history.push('/settings')}>Back</Button>
            <h1>Level Settings</h1>
            <Button color={'primary'} onClick={() => setOpen(true)} variant={'contained'}>Add Level</Button>
            <br/><br/>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Level Name</TableCell>
                            <TableCell>Level</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            levels && levels.map((l) => {
                                return (
                                    <TableRow key={l.id}>
                                        <TableCell>{l.levelName}</TableCell>
                                        <TableCell>{l.level}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            

            <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
                <form>
                    <DialogContent>
                    
                            <Typography>Level:</Typography>
                            <Slider
                                defaultValue={2}
                                step={1}
                                marks
                                min={2}
                                max={10}
                                onChangeCommitted={(e, val) => setLevel(val)}
                                valueLabelDisplay="on"
                            />
                            <TextField variant={'outlined'} fullWidth label={'Level Name'} required onChange={(e) => setLevelName(e.target.value)}/>
                            <br/>
                            { error ? <span className={'sim-error'}>{error}</span> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} color={'secondary'} onClick={() => setOpen(false)}>Cancel</Button>
                        <Button disabled={loading} color={'primary'} onClick={handleSubmit}>Confirm</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}
