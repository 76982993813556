import React from 'react'
import { FlatfileButton } from '@flatfile/react'
import { useAuth } from '../../../contexts/AuthContext';
import { storage } from '../../../firebase';


export default function VodacomOGRImport() {
    const { currentUser } = useAuth()
    const schema = [
        { label: 'Stock_Code', key: 'Stock_Code' },
        { label: 'Sales_Order', key: 'Sales_Order' },
        { label: 'Serial_Number', key: 'Serial_Number' },
        { label: 'Ref_Box_Number', key: 'Ref_Box_Number' },
        { label: 'Carton_Id', key: 'Carton_Id' },
    ]
    return (
        <div>
            <FlatfileButton
                disableManualInput={true}
                style={{
                  height: 50,
                  paddingLeft: 30,
                  paddingRight: 30,
                  backgroundColor: '#005ebc',
                  color: '#ffffff',
                  fontWeight: 'bold',
                  width: 200,
                  marginBottom: 10,
                  marginTop: 10,
                }}
                licenseKey="55050e52-01b4-476f-bb46-b0d5c48f8181"
                customer={{ userId: currentUser.uid }}
                settings={{
                  type: 'Contact',
                  fields: schema,
                  disableManualInput: true,
                }}
                onData={async (results) => {
                  // do something with the results
                  var jsonName =
                    'Upload_' +
                    [...Array(10)]
                      .map((i) => (~~(Math.random() * 36)).toString(36))
                      .join('');
                  var storageRef = storage.ref();
                  var fileRef = storageRef.child(
                    `/dataStar/vodacom/ogr/${jsonName}.json`
                  );
                  var file = results.allData;
                  const ndJson = file.map(JSON.stringify).join('\n');
                  var uploadTask = fileRef.putString(ndJson);
                  uploadTask.on(
                    'state_changed',
                    function (snapshot) {
                      var progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      console.log('Upload is ' + progress + '% done');
                    },
                    function (error) {
                      console.log(error, 'Error');
                      return 'There has been an error, please try again.';
                      // Handle unsuccessful uploads
                    },
                    function (res) {
                      // Handle successful uploads on complete
                      // Handle unsuccessful uploads
                      return 'Upload Complete!';
                    }
                  );
                }}
              >
                Import OGR
              </FlatfileButton>
        </div>
    )
}
