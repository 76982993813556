import React, { useState } from 'react'
import ForgotPassword from './ForgotPassword'
import SignIn from './Login'

export default function Authenticate({ logo }) {
    const [showForgot, setShowForgot] = useState(false)

    const toggleView = () => {
        setShowForgot(!showForgot)
    }

    return (
        showForgot
        ?
        <ForgotPassword toggleView={toggleView}/>
        :
        <SignIn toggleView={toggleView} logo={logo}/>
    )
}
