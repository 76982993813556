import React from 'react'
import { ImporterField } from 'react-csv-importer'

export default function MtnSchema() {
    return (
        <>
            <ImporterField name="kit" label="Kit Number" />
            <ImporterField name="kit_sim" label="Sim Number (ICCID)" />
            <ImporterField name="kit_box" label="Box Number'" />
            <ImporterField name="kit_brick" label="Brick Number" />
        </>
    )
}
