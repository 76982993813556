import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useDb } from '../../contexts/DatabaseContext';
import { Importer } from 'react-csv-importer';
import { useHistory } from 'react-router-dom'

// React CSV Importer CSS
import 'react-csv-importer/dist/index.css';

export default function SimCsvImporter({ network, simId, schema, fileType, msisdnId, month, year }) {
    const {
      importFinalConnections,
      createConnectionReportRecord,
      createActivationReportRecord,
      importActivations,
      importStock
    } = useDb()
    const [simData, setSimData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const history = useHistory()
    const [invoice, setInvoice] = useState(' ')

    // Confirmation Dialog
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [sims, setSims] = useState(0)
    const handleConfirmClose = () => {
      setConfirmOpen(false)
      
      setSims(0)
      history.push('/')
    }
    const handleConfirmOpen = (simAmt) => {
      setSims(simAmt)
      setConfirmOpen(true)
      setLoading(false)
    }

    const handleImport = async (records) => { 
      setLoading(true)

      if (fileType === 'stock') {
        await importStock(network, invoice, records).then(() => {
          handleConfirmOpen(records.length)
        })
      }
      if(fileType === 'activations') {
        await importActivations(records, network, year, month).then(async () => {
          await createActivationReportRecord(network, year, month, records.length)
          handleConfirmOpen(records.length)
        })
      }
      if(fileType === 'connections') {
        await importFinalConnections(records, network, year, month).then(async () => {
          await createConnectionReportRecord(network, year, month, records.length)
          handleConfirmOpen(records.length)
        })
      }
    }

    let tempArray = []
    
    return (
      loading ? 
        <div>
          <CircularProgress />
        </div>
        :
        <div>
            <Link to='/'><Button color="primary" startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}>Back</Button></Link>
            <h1>Import {network} {fileType}</h1>
            { error ? <Typography style={{ color: 'red' }}>{error}</Typography> : null }
            {
              fileType === 'stock'
              ?
              <TextField
                variant="outlined"
                fullWidth
                onChange={e => setInvoice(e.target.value)}
                label="Invoice Number"
                className="sim-text-field"
              />
              :
              null
            }
            
            <Importer
              processChunk={async (rows) => {
                for (var row of rows) {
                  if(row.serial_number) {
                    row.serial_number = row.serial_number.replace("\'", '')
                  }
                  if(row.msisdn) {
                    row.msisdn = row.msisdn.replace("\'", '')
                  }
                  tempArray.push(row)
                }
                setSimData(tempArray)
              }}
              onComplete={async () => {
                await handleImport(simData)
              }}
            >
              {schema}
            </Importer>

            <Dialog open={confirmOpen} maxWidth="sm" fullWidth onClose={handleConfirmClose}>
              <DialogContent>
                <Typography>
                  <b>Import Result:</b> {sims} Imported
                </Typography> 
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmClose} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faHome}/>}>Home</Button>
              </DialogActions>
            </Dialog>
        </div>
    )
}
