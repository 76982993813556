import React, { useState } from 'react'
import { selectFieldProps } from '../../../shared/constants'
import Select from 'react-select'
import { useDb } from '../../../../contexts/DatabaseContext'
import RICATable from './RICATable'


export default function RICAReport() {
  
  const { SubGetChildDealers } = useDb()
  const [month, setMonth] = useState(null)
  const [dealer, setDealer] = useState(null)
  
  const dealers = SubGetChildDealers()
  const dealerList = dealers.map(d => ({
    value: d.allocationArray[d.allocationArray.length - 1],
    label: d.dealerName
  }))

  return (
    <div>
      <h1>RICA Report</h1>
      <h2>Select Month</h2>
      <Select
        name='Select Months'
        onChange={(e) => setMonth(e.value)}
        options={[
          {label: 'January', value: 1},
          {label: 'Febuary', value: 2},
          {label: 'March', value: 3},
          {label: 'April', value: 4},
          {label: 'May', value: 5},
          {label: 'June', value: 6},
          {label: 'July', value: 7},
          {label: 'August', value: 8},
          {label: 'September', value: 9},
          {label: 'October', value: 10},
          {label: 'November', value: 11},
          {label: 'December', value: 12},
        ]}
        {...selectFieldProps}
        />
        <h2>Select Agent</h2>
        <Select
        name='Select Months'
        onChange={(e) => setDealer(e.value)}
        options={dealerList}
        {...selectFieldProps}
        />
        {
          dealer
          ?
          <RICATable dealer={dealer} month={month}/>
          :
          null
        }
    </div>
  )
}
