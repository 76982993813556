import { Button, Grid, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import Select from 'react-select'
import { useAuth } from '../../contexts/AuthContext'

export default function CreateDealer({ closeDialog }) {
    const { createDealer, GetLevelsBelow, getDealersAboveRank } = useDb() 
    const { currentUser } = useAuth()
    
    const [dealerName, setDealerName] = useState('')
    const [street, setStreet] = useState('')
    const [suburb, setSuburb] = useState('')
    const [city, setCity] = useState('')
    const [province, setProvince] = useState('')
    const [country, setCountry] = useState('') 
    const [contactName, setContactName] = useState('')
    const [contactSurname, setContactSurname] = useState('')
    const [contactIdNo, setContactIdNo] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [dealerType, setDealerType] = useState(null)
    const [parentDealer, setParentDealer] = useState(null)
    const [dealerList, setDealerList] = useState([])
    const [rank, setRank] = useState(null)
    
    // TODO: Set Levels Below to be dynamic
    const levels = GetLevelsBelow(1)
    
    const handleDealerTypeChange = async (val) => {
        setDealerType(val.value.levelName)
        setRank(val.value.level)
        const dealers = await getDealersAboveRank(val.value.level)
        setDealerList(dealers)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const result = await createDealer(dealerType, {
            dealerName,
            street,
            suburb,
            city,
            province,
            country,
            contactName,
            contactSurname,
            contactIdNo,
            email: email,
            phone,
            parentDealer: parentDealer ? parentDealer.id : currentUser.uid,
            rank,
            allocationKey: parentDealer ? parentDealer.allocationKey : currentUser.allocationKey
        })
        if (result !== null) {
            closeDialog()
        }
        else {
            setLoading(false)
            setError('Error saving to database. Check if user already exists.')
        }
    }
    return (
        <div>
            <h3>Create Dealer</h3>
            <form onSubmit={handleSubmit}>
                
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                            placeholder={'Dealer Level ...'}
                            options={levels}
                            onChange={handleDealerTypeChange}
                        
                        />
                        <br/>
                        {
                            dealerList ?
                            <Select
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                placeholder={'Parent Dealer ...'}
                                options={dealerList}
                                onChange={(val) => setParentDealer(val)}
                                // onChange={(val) => console.log(val)}
                            />
                            :
                            null
                        }
                        <br/>
                        <TextField label="Dealer Name / Trading Name" variant="outlined" fullWidth onChange={(e) => setDealerName(e.target.value)} required/><br/><br/>
                        <TextField label="Street Address" variant="outlined" fullWidth onChange={(e) => setStreet(e.target.value)} required/><br/><br/>
                        <TextField label="Suburb" variant="outlined" fullWidth onChange={(e) => setSuburb(e.target.value)} required/><br/><br/>
                        <TextField label="City" variant="outlined" fullWidth onChange={(e) => setCity(e.target.value)} required/><br/><br/>
                        <TextField label="Province" variant="outlined" fullWidth onChange={(e) => setProvince(e.target.value)} required/><br/><br/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField label="Country" variant="outlined" fullWidth onChange={(e) => setCountry(e.target.value)} required/><br/><br/>
                        <TextField label="Contact Name" variant="outlined" fullWidth onChange={(e) => setContactName(e.target.value)} required/><br/><br/>
                        <TextField label="Contact Surname" variant="outlined" fullWidth onChange={(e) => setContactSurname(e.target.value)} required/><br/><br/>
                        <TextField label="Contact ID Number" variant="outlined" fullWidth onChange={(e) => setContactIdNo(e.target.value)} required/><br/><br/>
                        <TextField type="email" label="Email" variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)}/><br/><br/>
                        <TextField type="text" label="Phone Number (+27 Format)" variant="outlined" fullWidth onChange={(e) => setPhone(e.target.value)} required/><br/><br/>
                        <span style={{ color: 'red' }}>{error}</span>
                        <Button disabled={loading || !dealerType} fullWidth type="submit" variant="contained" color="primary">Submit</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
