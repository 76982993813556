import { Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core'
import { AddBox, ArrowForward, HourglassEmpty } from '@material-ui/icons'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { networks, selectFieldProps, years, months } from '../../shared/constants'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Pagination } from '@material-ui/lab'

export default function ActivationReportAdmin() {
    const [network, setNetwork] = useState(null)
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)

    const { adminActivationRequest, GetActivationReports } = useDb()
    const mtnReports = GetActivationReports('mtn')
    const vcReports = GetActivationReports('vodacom')
    const tkReports = GetActivationReports('telkom')

    // Action State
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [addOpen, setAddOpen] = useState(false)

    // Pagination
    const itemsPerPage = 5; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(mtnReports.length / itemsPerPage)
    );
    const [vcPage, setVcPage] = useState(1);
    const [vcNoOfPages] = useState(
        Math.ceil(vcReports.length / itemsPerPage)
    );
    const [tkPage, setTkPage] = useState(1);
    const [tkNoOfPages] = useState(
        Math.ceil(tkReports.length / itemsPerPage)
    );


    const runReport = async () => {
        setLoading(true)
        setError(null)
        try {
            await adminActivationRequest(network, year, month)
            setLoading(false)
            setAddOpen(false)
            setNetwork(null)
            setYear(null)
            setMonth(null)
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <h1>Activation Reports</h1>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="MTN"
                                />
                                <CardContent>
                                    <List>
                                        {
                                            mtnReports && mtnReports
                                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                            .map(r => (
                                                <ListItem key={r.id} divider>
                                                    <ListItemText>
                                                        {r.year} - {r.month} ({moment(r.timestamp.toDate()).fromNow()})
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                    {
                                                        r.json ?
                                                            <Link to={`/json-report/mtn/activation/${r.id}/${r.month}/${r.year}`}>
                                                                <IconButton>
                                                                    <ArrowForward />
                                                                </IconButton>
                                                            </Link>
                                                        :
                                                        <Tooltip title="Report Still Running">
                                                            <IconButton><HourglassEmpty /></IconButton>
                                                        </Tooltip>
                                                    }
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    
                                </CardContent>
                                <CardActions>
                                    <Pagination
                                        count={Math.ceil(mtnReports.length / itemsPerPage)}
                                        page={page}
                                        onChange={(e, val) => setPage(val)}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                    />
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Vodacom"
                                />
                                <CardContent>
                                    <List>
                                        {
                                            vcReports && vcReports
                                            .slice((vcPage -1) * itemsPerPage, vcPage * itemsPerPage)
                                            .map(r => {
                                                console.log(r)
                                                return (
                                                    <ListItem key={r.id} divider>
                                                        <ListItemText>{r.year} - {r.month} ({moment(r.timestamp.toDate()).fromNow()})</ListItemText>
                                                        <ListItemSecondaryAction>
                                                            {
                                                                r.json ?
                                                                <Link to={`/json-report/vodacom/activation/${r.id}/${r.month}/${r.year}`}>
                                                                    <IconButton>
                                                                        <ArrowForward />
                                                                    </IconButton>
                                                                </Link>
                                                                :
                                                                <Tooltip title="Report Still Running">
                                                                    <IconButton><HourglassEmpty /></IconButton>
                                                                </Tooltip>
                                                            }
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                    
                                </CardContent>
                                <CardActions>
                                    <Pagination
                                        count={Math.ceil(vcReports.length / itemsPerPage)}
                                        page={page}
                                        onChange={(e, val) => setVcPage(val)}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                    />
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Telkom"
                                />
                                <CardContent>
                                    <List>
                                        {
                                            tkReports && tkReports
                                            .slice((tkPage -1) * itemsPerPage, tkPage * itemsPerPage)
                                            .map(r => {
                                                console.log(r)
                                                return (
                                                    <ListItem key={r.id} divider>
                                                        <ListItemText>{r.year} - {r.month} ({moment(r.timestamp.toDate()).fromNow()})</ListItemText>
                                                        <ListItemSecondaryAction>
                                                            {
                                                                r.json ?
                                                                <Link to={`/json-report/telkom/activation/${r.id}/${r.month}/${r.year}`}>
                                                                    <IconButton>
                                                                        <ArrowForward />
                                                                    </IconButton>
                                                                </Link>
                                                                :
                                                                <Tooltip title="Report Still Running">
                                                                    <IconButton><HourglassEmpty /></IconButton>
                                                                </Tooltip>
                                                            }
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                    
                                </CardContent>
                                <CardActions>
                                    <Pagination
                                        count={Math.ceil(tkReports.length / itemsPerPage)}
                                        page={page}
                                        onChange={(e, val) => setTkPage(val)}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                    />
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                    { error ? <span className="sim-error">{error}</span> : null }
                </CardContent>
                <CardActions>
                    <Button onClick={() => setAddOpen(true)} color="primary" variant="contained" startIcon={<AddBox />}>New Report</Button>
                </CardActions>
            </Card>
            <Dialog maxWidth="sm" fullWidth open={addOpen} onClose={() => setAddOpen(false)}>
                <DialogContent>
                    <Select
                        {...selectFieldProps}
                        options={networks}
                        placeholder="Select Network ..."
                        onChange={v => setNetwork(v.value)}
                    />
                    <br/>
                    <Select
                        {...selectFieldProps}
                        options={years}
                        placeholder="Select Year ..."
                        onChange={v => setYear(v.value)}
                    />
                    <br/>
                    <Select
                        {...selectFieldProps}
                        options={months}
                        placeholder="Select Month ..."
                        onChange={v => setMonth(v.value)}
                    />
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={runReport} color="primary" variant="contained" disabled={ !network || !year || !month || loading }>Run Report</Button>
                </DialogActions>
            </Dialog>
                   
        </div>
    )
}
