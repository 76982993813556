import { Avatar, Card, CardActions, CardContent, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { ArrowForward, SimCard } from '@material-ui/icons'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { Link } from 'react-router-dom'


export default function DealerActsNu() {
    const { GetUserActivations } = useDb()
    const acts = GetUserActivations()
    console.log(acts)
    return (
        <div>
            <h1>Activation Reports</h1>
            <Card>
                <CardContent>
                    <List>
                        {
                            acts && acts.map(a => (
                                <ListItem key={a.id} divider>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <SimCard />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText>{a.network} - {a.year} - {a.month} | Activations: {a.activations}</ListItemText>
                                    <ListItemSecondaryAction>
                                        <Link to={`/dealer-reports/activations/activation-summary/${a.id}/${a.activations}`}>
                                            <IconButton>
                                                <ArrowForward />
                                            </IconButton>
                                        </Link>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>
                </CardContent>
                <CardActions>

                </CardActions>
            </Card>
        </div>
    )
}
