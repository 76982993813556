import React, { useState } from 'react'
import { networks } from '../../shared/constants'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { Button, CircularProgress, DialogContent, TextField, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { Dialog } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'

export default function AllocateInvoice() {
    const [network, setNetwork] = useState(null)
    const { GetAllDealers, searchInvoice, allocateInvoice } = useDb()
    const dealers = GetAllDealers()
    const [dealer, setDealer] = useState(null)
    const [searching, setSearching] = useState(false)
    const [idType, setIdType] = useState('invoice')
    const [invoiceNumber, setInvoiceNumber] = useState(null)
    const [error, setError] = useState(null)
    const [searchOK, setSearchOK] = useState(false)
    const history = useHistory()
    const [confirmOpen, setConfirmOpen] = useState(false)

    const dealerOptions = dealers.map((d) => ({
        value: d,
        label: `${d.contactName} ${d.contactSurname} - (${d.dealerType})`
    }))

    const handleNetworkChange = (val) => {
        setNetwork(val.value)
    }

    const handleSearch = async () => {
        setSearching(true)
        setError(null)
        try {
            await searchInvoice(network, invoiceNumber).then(() => {
                setSearchOK(true)
                setSearching(false)
            })
        }
        catch(err) {
            setError(err.message)
            setSearching(false)
        }
    }

    const handleAllocate = async () => {
        setSearching(true)
        try {
            await allocateInvoice(network, dealer, invoiceNumber).then(() => {
                setConfirmOpen(true)
            })
        }
        catch(err) {

        }
    }

    const handleExit = () => {
        setConfirmOpen(false)
        history.push('/')
    }

    return (
        <div>
            <h1>Allocate Invoice</h1>
            <Select
                options={dealerOptions}
                onChange={(val) => setDealer(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Dealer'}
            />
            <br/>
            <Select
                options={networks}
                placeholder={'Select Network ...'}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                onChange={handleNetworkChange}
            />
            <br/>
            {
                network && dealer
                ?
                <div>
                    <TextField 
                        label={'Search Invoice Number'}
                        fullWidth
                        variant={'outlined'}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                    <br/><br/>
                    <Button onClick={handleSearch} disabled={searching} startIcon={<Search />} color={'primary'} variant={'contained'}>Search</Button>
                    {
                        error 
                        ?
                        <Typography>Error: {error}</Typography>
                        :
                        null
                    }
                    {
                        searchOK 
                        ?
                        <div>
                            <Typography>Invoice {invoiceNumber} Found</Typography>
                            <Button onClick={handleAllocate} disabled={searching} startIcon={<FontAwesomeIcon icon={faBoxes}/>} color={'primary'} variant={'contained'}>Allocate Stock</Button>
                        </div>
                        :
                        null
                    }
                </div>
                : 
                null
            }
            {
                searching
                ?
                <CircularProgress />
                :
                null
            }

            <Dialog open={confirmOpen} onClose={handleExit} maxWidth="sm" fullWidth>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>Invoice Allocated</DialogContent>
                <DialogActions>
                    <Button onClick={handleExit} color="primary" variant="contained" fullWidth>Exit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
