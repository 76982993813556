import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'

export default function SimTile({ sim }) {

    return (
        <TableRow>
            <TableCell>{sim.id}</TableCell>
            <TableCell>{sim.connected ? <b>Yes</b> : 'N'}</TableCell>
            <TableCell>{sim.activated ? <b>Yes</b> : 'N'}</TableCell>
        </TableRow>
    )
}
