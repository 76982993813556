import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import Notification from './Notification'

export default function LatestNotifs() {
    const { GetLatestNotifs } = useDb()
    const notifs = GetLatestNotifs()
    return (
        <>
          { notifs && notifs.map((n) => {
              return (
                  <Notification key={n.id} n={n} />
              )
          })}  
        </>
    )
}
