import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyCn-x-ykuSo3-jE7gq_7g1dGAB4a1y3lfw',
  authDomain: 'simboss-e115d.firebaseapp.com',
  databaseURL: 'https://simboss-e115d.firebaseio.com',
  projectId: 'simboss-e115d',
  storageBucket: 'simboss-e115d.appspot.com',
  messagingSenderId: '346727735301',
  appId: '1:346727735301:web:5aff6fba8b19751853bc6c',
});

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const fieldValue = firebase.firestore.FieldValue;
export const authFunc = firebase.auth;

export default app;
