import React from 'react'
import { ImporterField } from 'react-csv-importer'

export default function MtnConnectionsSchema() {
    return (
        <>
            <ImporterField name="msisdn" label="MSISDN" />
            <ImporterField name="sim" label="Sim Number (ICCID)" />
            <ImporterField name="kit" label="Kit Number (Serial)" />
        </>
    )
}
