import { Avatar, Card, CardContent, CardHeader, Grid, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core'
import { SimCard } from '@material-ui/icons'
import React from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import { efficiencyChartOptions } from '../../shared/constants';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: 10
    },
    chartDiv: {
        textAlign: 'center'
    }
})) 

export default function DealerStockReport({ report }) {
    const classes = useStyles()

    const conTotal = report.results.c2020 + report.results.c2021 + report.results.c2022 + report.results.c2023
    const actTotal = report.results.a2020 + report.results.a2021 + report.results.a2022 + report.results.a2023

    return (
        <Card className={classes.card}>
            <CardHeader
                title={report.network.toUpperCase()}
                avatar={
                    <Avatar>
                        <SimCard />
                    </Avatar>
                }
            />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography>Total: {report.results.total}</Typography>
                        <Typography>Downstream Allocation: {report.results.allocated}</Typography>
                        <Typography>Connected: {conTotal}</Typography>
                        <Typography>Activated: {actTotal}</Typography>
                        <br/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <div className={classes.chartDiv}>
                                    Connection Rate
                                    <Chart
                                        type="radialBar"
                                        options={efficiencyChartOptions}
                                        series={[conTotal / report.results.total * 100]}
                                        height={150}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.chartDiv}>
                                    Activation Rate
                                    <Chart
                                        type="radialBar"
                                        options={efficiencyChartOptions}
                                        series={[actTotal / report.results.total * 100]}
                                        height={150}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </CardContent>
            
        </Card>
    )
}
