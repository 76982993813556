import { Button, CardContent, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import PropTypes from 'prop-types';
import ActivationReportTab from './ActivationReportTab';
import { ArrowLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
            {value === index && (
                <CardContent className="iso-card-content">
                    <Typography component={'span'}>{children}</Typography>
                </CardContent>  
            )}
            </div>
        );
    }
    
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ActivationReportDetail() {
    const params = useParams()
    const requestId = params.requestid 
    const { GetActivationsDealerReport } = useDb()
    const entries = GetActivationsDealerReport(requestId)
    const [tab, setTab] = useState(0)
    const history = useHistory()
    const { GetLevels } = useDb()
    const levels = GetLevels()
    console.log(levels)

    return (
        <div>
            <Button startIcon={<ArrowLeft />} color="primary" onClick={() => history.push('/reports/activations')}>Back</Button>
            <Paper>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} indicatorColor="primary" >
                    
                    {
                        levels && levels.map((lv) => {
                            return (
                                <Tab label={lv.label} {...a11yProps(lv.level-1)} key={lv.id}/>
                            )
                        })
                    }
                    <CSVLink
                        data={entries}
                        style={{ marginTop: 5, textDecoration: 'none'}}
                    ><Button startIcon={<FontAwesomeIcon icon={faFileExcel}/>} style={{ color: 'green'}}> Export to CSV</Button></CSVLink>
                </Tabs>
                {
                    levels && levels.map((lv) => {
                        return (
                            <TabPanel value={tab} index={lv.level-1} key={lv.id}>
                                <ActivationReportTab dealerType={lv.level} entries={entries} dealerLabel={lv.label} />
                            </TabPanel>
                        )
                    })
                }
            </Paper>
        </div>
    )
}
