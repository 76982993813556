import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@material-ui/core';
import { PhoneAndroid } from '@material-ui/icons';
import React, { useState } from 'react'
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import { useDb } from '../../contexts/DatabaseContext';
import CustomSimDataTable from './customtable/CustomSimDataTable';

export default function JsonReportDetail({ dealers, reportId, network, type }) {

    const { month, year } = useParams()

    const [state, setState] = useState({
        loading: false,
        error: null
    })

    const { publishReportToAgents } = useDb()

    const rows = dealers && dealers.map(d => ({
        id: d.id,
        dealerName: `${d.contactName} ${d.contactSurname}`,
        phone: d.phone,
        count: d.count,
        
    }))

    const columns = [
        { field: 'dealerName', headerName: 'Name', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 200 },
        { field: 'count', headerName: 'Count', width: 200 },
    ]

    const handlePublish = async () => {
        setState({
            loading: true,
            error: null
        })
        try {
            await publishReportToAgents(rows, month, year, network, type)
            setState({
                loading: false,
                error: null,
                message: 'Reports Sent!'
            })
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    return (
        <div>
            <CustomSimDataTable
                rows={rows}
                columns={columns}
                network={network}
                type={type}
                reportId={reportId}
                month={month}
                year={year}
            />
            <br />
            {
                state.message ? <Typography>{state.message}</Typography> : <Typography>{state.error}</Typography>
            }
            <Button disabled={state.loading} onClick={handlePublish} color="primary" variant="contained" startIcon={<PhoneAndroid />}>Publish Results</Button>
            &nbsp;
            <CSVLink
                data={rows}
                style={{ marginTop: 5, textDecoration: 'none'}}
                filename={`${network} ${type}`}
            >
                <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFileExcel}/>} style={{ backgroundColor: 'green', color: '#fff'}}> Export view to CSV</Button>
            </CSVLink>
        </div>
    )
}
