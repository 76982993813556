import React, { useState } from 'react'
import { Importer, ImporterField } from 'react-csv-importer';
import { jsonToCSV, CSVDownloader } from 'react-papaparse'
import { useAuth } from '../../../contexts/AuthContext';
import { storage } from '../../../firebase'

export default function OGRImporter() {
    const [ogrFormatted, setOgrFormatted] = useState(null)
    let tempArray = []
    
    const { currentUser } = useAuth()
    const storageRef = storage.ref('users').child(currentUser.uid)
    const uploadFormattedCsv = async (csv) => {
        const fileName = Math.random().toString(36).slice(-10)
        const uploadTask = storageRef.child(`documents/${fileName}.csv`).put(csv)
        uploadTask.on(
            'state_changed',
            snapshot => {
                var upProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // setProgress(upProgress)
            },
            error => {
                console.log(error)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    console.log(url)
                })
            }
        )
    }


    return (
        <div>
            <Importer
              processChunk={async (rows) => {
                for (var row of rows) {
                  tempArray.push(row)
                }
              }}
              onComplete={async (data) => {
                const csv = await jsonToCSV(tempArray)
                setOgrFormatted(tempArray)
                uploadFormattedCsv(csv)
              }}
            >
                {/* TODO: Replace b elow with dynamic schema */}
                <ImporterField name="msisdn" label="MSISDN" />
                <ImporterField name="kit_sim" label="Sim Number (ICCID)" />
                <ImporterField name="rev_0" label="Revenue Month 0" />
                
            </Importer>

            {
                ogrFormatted
                ?
                <CSVDownloader
                    data={ogrFormatted}
                    filename={'filename'}
                >
                    Download
                </CSVDownloader>
                :
                null
            }
        </div>
    )
}
