import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../contexts/DatabaseContext'
import { provinces, selectFieldProps, textFieldProps } from '../shared/constants'

export default function EditDealer({ dealer, open, closeDialog }) {
    const { updateDealer } = useDb()

    // Form State
    const [state, setState] = useState({
        city: dealer.city,
        contactName: dealer.contactName,
        contactIdNo: dealer.contactIdNo,
        contactSurname: dealer.contactSurname,
        country: dealer.country,
        dealerName: dealer.dealerName,
        phone: dealer.phone,
        province: dealer.province,
        street: dealer.street,
        suburb: dealer.suburb
    })

    // Action State
    const [updated, setUpdated] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
        setUpdated(true)
    }

    const handleSelect = (v) => {
        setState({
            ...state,
            province: v.value
        })
        setUpdated(true)
    }
    
    const handleClose = () => {
        setUpdated(false)
        setLoading(false)
        closeDialog()
    }

    const handleSubmit = async () => {
        setError(null)
        setLoading(true)
        try {
            await updateDealer(dealer.id, state).then(() => {
                handleClose()
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Edit Dealer</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            {...textFieldProps}
                            value={state.contactName}
                            label="First Name(s)"
                            onChange={handleChange}
                            name="contactName"
                        />
                        <TextField
                            {...textFieldProps}
                            value={state.contactSurname}
                            label="Last Name"
                            onChange={handleChange}
                            name="contactSurname"
                        />
                        <TextField
                            {...textFieldProps}
                            value={state.contactIdNo}
                            label="ID Number"
                            onChange={handleChange}
                            name="contactIdNo"
                        />
                        <TextField
                            {...textFieldProps}
                            value={state.phone}
                            label="Phone Number"
                            onChange={handleChange}
                            name="phone"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            {...textFieldProps}
                            value={state.street}
                            label="Street Address"
                            onChange={handleChange}
                            name="street"
                        />
                        <TextField
                            {...textFieldProps}
                            value={state.suburb}
                            label="Suburb"
                            onChange={handleChange}
                            name="suburb"
                        />
                        <TextField
                            {...textFieldProps}
                            value={state.city}
                            label="City"
                            onChange={handleChange}
                            name="city"
                        />
                        Province: {state.province}
                        <Select
                            options={provinces}
                            {...selectFieldProps}
                            onChange={handleSelect}
                            placeholder="Change Province ..."
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                { error ? <span className="sim-error">{error}</span> : null }
                <Button disabled={loading} color="secondary" onClick={handleClose}>Cancel</Button>
                <Button disabled={loading || !updated } color="primary" onClick={handleSubmit} variant="contained" startIcon={<Save />}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}
