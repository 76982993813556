import { Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'

export default function SubDealerDetail() {
    const { dealerId } = useParams()
    const { getSubDealerDetails } = useDb()
    const [d, setD] = useState(null)
    const [dealerLoaded, setDealerLoaded] = useState(false)

    const retrieveDealer = async () => {
        const dealer = await getSubDealerDetails(dealerId)
        setD(dealer)
        setDealerLoaded(true)
    }

    if(!dealerLoaded) {
        retrieveDealer()
        return (
            <CircularProgress />
        )
    }

    else return (
        <div>
            <h1>{d.contactName} {d.contactSurname}</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Card>
                        <CardContent>
                            <Typography>Level: {d.rank} - {d.selectedLevel.levelName}</Typography>
                            <Typography>Phone Number: {d.phone}</Typography>
                            <Typography>Email: {d.email}</Typography>
                            <Typography>Street: {d.street}</Typography>
                            <Typography>City: {d.city}</Typography>
                            <Typography>Province: {d.province}</Typography>
                            <Typography>Created On: {moment(d.created.toDate()).format('DD/MM/YYYY')}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Card>
                        <CardContent>
                            <Link to={`/efficiency/${dealerId}/${d.rank}`}>
                                <Button variant="contained" color="primary">Check Dealer Efficiency</Button>
                            </Link>
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </div>
    )
}
