import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import MainMenu from './MainMenu'
import DatabaseProvider from '../contexts/DatabaseContext'
import DealerMenu from './DealerMenu'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import PublicRoutes from './routes/PublicRoutes'
import jpLogo from '../img/jpLong.png'
import chadezLogo from '../img/chadez.png'
import afrahLogo from '../img/afrah.png'
import dsLogo from '../img/datastar2.png'

export default function Wrapper() {
    const { currentUser, userLevel } = useAuth() 

    const chadezTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#126289',
                contrastText: '#fff'
            }
        }
    })

    const jurassicTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#a53922',
                contrastText: '#fff'
            }
        }
    })

    const afrahTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#102b56',
                contrastText: '#fff'
            }
        }
    })

    const datastarTheme = createMuiTheme({
        palette: {
            primary: {
                main: '#010f30',
                contrastText: '#fff'
            }
        }
    })

    // Companies. Jurassic is our dev environment
    const companies = {
        jurassic: {
            theme: jurassicTheme,
            logo: jpLogo
        },
        chadez: {
            theme: chadezTheme,
            logo: chadezLogo
        },
        afrah: {
            theme: afrahTheme,
            logo: afrahLogo
        },
        datastar: {
            theme: datastarTheme,
            logo: dsLogo
        }
    }

    const company = companies.datastar 
    
    return (
        <BrowserRouter>
            <ThemeProvider theme={company.theme}>
                {
                    currentUser && currentUser.uid 
                    ?
                        userLevel === 1
                        ?
                        <DatabaseProvider companyId={currentUser.companyId}>
                            <MainMenu logo={company.logo}/>
                        </DatabaseProvider>   
                        :
                        <DatabaseProvider companyId={currentUser.companyId}>
                            <DealerMenu logo={company.logo}/>
                        </DatabaseProvider>   
                    :
                    <PublicRoutes logo={company.logo}/>
                }
            </ThemeProvider>
            
        </BrowserRouter>
    )
}
