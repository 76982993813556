import React from 'react'
import AllocateBox from '../allocate-stock/box/AllocateBox'
import AllocateBrick from '../allocate-stock/brick/AllocateBrick'
import CheckStock from '../allocate-stock/check/CheckStock'
import DealerAllocateBoxes from '../dealercomps/allocation/DealerAllocateBoxes'
import DealerAllocateStock from '../dealercomps/allocation/DealerAllocateStock'
import DealerSelectType from '../dealercomps/allocation/DealerSelectType'
import DealerDashboard from '../dealercomps/DealerDashboard'
import DealerActsNu from '../dealercomps/reports/DealerActsNu'
import DealerEfficiency from '../dealercomps/reports/DealerEfficiency'
import DealerReports from '../dealercomps/reports/DealerReports'
import DrillDownReport from '../dealercomps/reports/DrillDownReport'
import DealerActivationSummary from '../dealercomps/reports/newviews/DealerActivationSummary'
import SubDealerManage from '../dealercomps/SubDealerManage'
import SubDealerDetail from '../dealercomps/subdealers/SubDealerDetail'
import PrivateRoute from './PrivateRoute'
import RicaForm from '../settings/rica/RicaForm'
import DealerConsNu from '../dealercomps/reports/newviews/DealerConsNu'
import DealerConnectionSummary from '../dealercomps/reports/newviews/DealerConnectionSummary'
import RDealerConnections from '../dealercomps/reports/refactor/RDealerConnections'
import DealerViewJsonReport from '../dealercomps/reports/refactor/DealerViewJsonReport'
import RICAReport from '../dealercomps/reports/newviews/RICAReport'

export default function DealerRoutes() {
    return (
        <>
            <PrivateRoute exact path='/' component={DealerDashboard} />  
            <PrivateRoute exact path='/allocate-stock/' component={DealerAllocateStock} /> 
            <PrivateRoute path='/allocate-stock/:network/select' component={DealerSelectType} /> 
            <PrivateRoute path='/allocate-stock/:network/boxes' component={DealerAllocateBoxes} /> 
            
            <PrivateRoute path='/allocate-stock/box' component={AllocateBox} />  
            <PrivateRoute path='/allocate-stock/brick' component={AllocateBrick} />  
            <PrivateRoute exact path='/manage-dealers' component={SubDealerManage} />  
            <PrivateRoute path='/manage-dealers/:dealerId' component={SubDealerDetail} />  
            <PrivateRoute exact path='/dealer-reports' component={DealerReports} />  
            <PrivateRoute exact path='/dealer-reports/activations' component={DealerActsNu} />  
            <PrivateRoute exact path='/dealer-reports/rica-report' component={RICAReport} />
            <PrivateRoute path='/dealer-reports/activations/activation-summary/:reportId/:total' component={DealerActivationSummary} />  
            <PrivateRoute path='/dealer-reports/connections/connection-summary/:reportId/:total' component={DealerConnectionSummary} />  

            <PrivateRoute path='/check' component={CheckStock} />  
            <PrivateRoute path='/dd-report/:type/:rank/:dealerId/:year/:month/:network' component={DrillDownReport} key={window.location.pathname}/>  
            <PrivateRoute path='/efficiency/:dealerId/:rank' component={DealerEfficiency} />  
            <PrivateRoute path='/rica' component={RicaForm} />  

            <PrivateRoute exact path='/dealer-reports/connections' component={RDealerConnections} />  
            <PrivateRoute exact path='/dealer-reports/connections-report/:network/:type/:reportId' component={DealerViewJsonReport} />  
        </>
    )
}
