import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import { useParams, useHistory } from 'react-router-dom'
import SimDataGrid from '../../shared/data-grid/SimDataGrid'
import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

export default function AgentsEfficiency() {
    const { parentDealer, network } = useParams()
    const { GetDealerAgentsEfficiency } = useDb()
    const agents = GetDealerAgentsEfficiency(parentDealer, network)
    const history = useHistory()

    const rows = agents && agents.map(a =>({
        id: a.id,
        activations: a.results.a2020 + a.results.a2021 + a.results.a2022 + a.results.a2023,
        actRate: `${(((a.results.a2020 + a.results.a2021 + a.results.a2022 + a.results.a2023) / a.results.total) * 100).toFixed(0)} %`,
        allocated: a.results.allocated,
        connections: a.results.c2020 + a.results.c2021 + a.results.c2022 + a.results.c2023,
        conRate: `${(((a.results.c2020 + a.results.c2021 + a.results.c2022 + a.results.c2023) / a.results.total) * 100).toFixed(0)} %`,
        total: a.results.total,
        notCons: a.results.notCons,
        dealerName: a.dealerName,
        phone: a.phone
    }))

    const columns = [
        { field: 'dealerName', headerName: 'Name' , width: 200 },
        { field: 'total', headerName: 'Total:', width: 150 },
        { field: 'connections', headerName: 'Connected' , width: 150 },
        { field: 'conRate', headerName: 'Conn. Rate' , width: 150 },
        { field: 'activations', headerName: 'Activated' , width: 150 },
        { field: 'actRate', headerName: 'Act. Rate' , width: 150 },
        { field: 'phone', headerName: 'Phone' , width: 150 }
    ]

    return (
        <div>
            <div className="back-link">
                <Button
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                    color="primary"
                >
                    Back
                </Button>
            </div>
            <SimDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
