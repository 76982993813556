import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Importer } from 'react-csv-importer'
import { useDb } from '../../../contexts/DatabaseContext'
import { useHistory } from 'react-router-dom'
import { Home } from '@material-ui/icons'

export default function DailyImporter({ schema, network }) {
    const history = useHistory()
    const { importDailyConnections } = useDb()
    const [loading, setLoading] = useState(false)
    const [records, setRecords] = useState()

    // Confirmation Dialog
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [sims, setSims] = useState(0)
    const handleConfirmClose = () => {
      setConfirmOpen(false)
      setSims(0)
      history.push('/')
    }
    const handleConfirmOpen = (simAmt) => {
      setSims(simAmt)
      setConfirmOpen(true)
      setLoading(false)
    }

    const monthKeys = {
        mtn: {
            "01": "January",
            "02": "February",
            "03": "March",
            "04": "April",
            "05": "May",
            "06": "June",
            "07": "July",
            "08": "August",
            "09": "September",
            "10": "October",
            "11": "November",
            "12": "December",
        }
    }

    const handleImport = async (rows) => {
        let recordArr = []
        for (var row of rows) {
            // Different Networks handle the reporting of what month it is differently, so you hacve to handle that for each network
            if(network === 'mtn') {
                const { kit, sim, msisdn, month } = row
                recordArr.push({ 
                    connectionYear: month.substring(0,4),
                    connectionMonth: monthKeys[network][month.slice(-2)], 
                    sim, 
                    kit, 
                    msisdn, 
                })
            }
        }
        
        // You now have the records, it's time to build a new import function that handles this dynamically generated data and updates BigQUery
        await importDailyConnections(recordArr, network)
        // TODO: OOOOOH OK we had to create the connections record so we can count unmatched sims. 
        handleConfirmOpen(records.length)
    }

    let tempArray = []

    return (
        loading ? 
        <div>
          <CircularProgress />
        </div>
        :
        <div>
            <Importer
                processChunk={async (rows) => {
                    for (var row of rows) {
                        tempArray.push(row)
                    }
                    setRecords(tempArray)
                }}
                onComplete={() => {
                    handleImport(records)
                }}
            >
                    {schema}
            </Importer>

            <Dialog open={confirmOpen} maxWidth="sm" fullWidth onClose={handleConfirmClose}>
              <DialogContent>
                <Typography>
                  <b>Import Result:</b> {sims} Imported
                </Typography> 
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmClose} variant="contained" color="primary" startIcon={<Home />}>Home</Button>
              </DialogActions>
            </Dialog>
        </div>
    )
}
