import React from 'react'
import { ImporterField } from 'react-csv-importer'

export default function VodacomConnectionSchema() {
    return (
        <>
            <ImporterField name="serial_number" label="Sim Number (ICCID)" />
            <ImporterField name="msisdn" label="MSISDN" />
        </>
    )
}
