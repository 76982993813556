import React from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

export default function ConnectionReportTab({ entries, dealerType, dealerLabel }) {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Dealer Type</TableCell>
                            <TableCell>No. Connections</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            entries && entries.map((entry) => {
                                if (entry.rank === dealerType)
                                return (
                                    <TableRow key={entry.id}>
                                        <TableCell>{entry.contactName}</TableCell>
                                        <TableCell>{entry.contactSurname}</TableCell>
                                        <TableCell>{dealerLabel}</TableCell>
                                        <TableCell>{entry.connectionTotal}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
