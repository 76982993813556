import { faTruck, faIdCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import allocateStock from '../../img/allocate-stock.svg'
import reports from '../../img/reports.svg' 
import manageDealers from '../../img/manage-dealers.svg'

export default function DealerDashboard() {
    return (
        <div>
            <h1>Dealer Dashboard</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader 
                            title={'Allocate Stock'}
                            avatar={<FontAwesomeIcon icon={faTruck}/>}
                        />
                        <CardContent className="sim-card-content">
                            <img src={allocateStock} width="100%" height="100"/>
                        </CardContent>
                        <CardActions>
                            <Link to={'/allocate-stock/box'}><Button color="primary">Box</Button></Link>
                            <Link to={'/allocate-stock/brick'}><Button color="primary">Brick</Button></Link>
                            <Link to={'/check'}><Button color="primary">Check</Button></Link>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader 
                            title={'Manage Dealers'}
                            avatar={<FontAwesomeIcon icon={faIdCard}/>}
                        />
                        <CardContent className="sim-card-content">
                            <img src={manageDealers} width="100%" height="100"/>
                        </CardContent>
                        <CardActions>
                            <Link to='/manage-dealers'>
                                <Button color="primary">Your Team</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader 
                            title={'Dealer Reports'}
                            avatar={<FontAwesomeIcon icon={faTruck}/>}
                        />
                        <CardContent className="sim-card-content">
                            <img src={reports} width="100%" height="100"/>
                        </CardContent>
                        <CardActions>
                            <Link to={'/dealer-reports'}><Button color="primary">Check Reports</Button></Link>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
