import React from 'react'
import MtnDailyConnectionsSchema from '../mtn/MtnDailyConnectionsSchema'
import DailyImporter from './DailyImporter'

export default function MtnDailyImport() {
    return (
        <div>
            <DailyImporter
                schema={<MtnDailyConnectionsSchema />}
                network="mtn"
            />
        </div>
    )
}
