import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { ArrowBack, People, Person, SimCard } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'

export default function SearchResults() {
    const { id } = useParams()
    const history = useHistory()
    const { getSingleSearchResult } = useDb()
    const [result, setResult] = useState()
    const [state, setState] = useState({
        pageLoading: true,
        error: null
    })
   

    const getResults = async () => {
        const r = await getSingleSearchResult(id)
        console.log(r)
        if(r) {
            setResult(r)
            setState({
                ...state,
                pageLoading: false
            })
        }
        else {
            setState({
                pageLoading:false,
                error: 'Error: No results found.'
            })
        }
    }

    if(state.pageLoading) {
        getResults()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>Search Results</h1>
            <div className="back-link">
                <Button color="primary" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
            </div>
            {
                state.error
                ?
                <Typography className="sim-error">{state.error}</Typography>
                :
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                
                                <Card>
                                    <CardHeader
                                        title="SIMS"
                                        avatar={
                                        <Avatar>
                                            <SimCard />
                                        </Avatar>    
                                        }
                                    />
                                    <CardContent>
                                        <Typography>SIMs found: {result.results.length}</Typography>
                                        <Typography>Num. Connected: {result.conCount}</Typography>
                                        <Typography>Num. Activated: {result.actCount}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardHeader
                                        title="Dealers Allocated To"
                                        avatar={
                                        <Avatar>
                                            <People />
                                        </Avatar>    
                                        }
                                    />
                                    <CardContent>
                                        {
                                            result.dealers && result.dealers.map(d => {
                                                if(d.contactName) {
                                                    return (
                                                        <ListItem key={d.id}>
                                                            <ListItemIcon><Person /></ListItemIcon>
                                                            <ListItemText>{d.contactName} {d.contactSurname}</ListItemText>
                                                        </ListItem>
                                                    )
                                                }
                                            })
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }
        </div>
    )
}
