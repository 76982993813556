import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core'
import { ArrowForward, HourglassEmpty } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Pagination } from '@material-ui/lab'

export default function RDealerConnections() {
    const { GetConnectionReportRequests } = useDb()
    const mtnReports = GetConnectionReportRequests('mtn')
    const vcReports = GetConnectionReportRequests('vodacom')

    // Action State
    const [error, setError] = useState(null)

    // Pagination
    const itemsPerPage = 5; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(mtnReports.length / itemsPerPage)
    );
    const [vcPage, setVcPage] = useState(1);
    const [vcNoOfPages] = useState(
        Math.ceil(vcReports.length / itemsPerPage)
    );


    return (
        <div>
            <h1>Connection Reports</h1>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="MTN"
                                />
                                <CardContent>
                                    <List>
                                        {
                                            mtnReports && mtnReports
                                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                            .map(r => (
                                                <ListItem key={r.id} divider>
                                                    <ListItemText>{r.year} - {r.month} ({moment(r.timestamp.toDate()).fromNow()})</ListItemText>
                                                    <ListItemSecondaryAction>
                                                    {
                                                        r.json
                                                        ?
                                                            <Link to={`/dealer-reports/connections-report/mtn/connection/${r.id}`}>
                                                                <IconButton>
                                                                    <ArrowForward />
                                                                </IconButton>
                                                            </Link>
                                                        :
                                                        <Tooltip title="Report Still Running">
                                                            <IconButton><HourglassEmpty /></IconButton>
                                                        </Tooltip>
                                                    }
                                                    </ListItemSecondaryAction>
                                                    
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    
                                </CardContent>
                                <CardActions>
                                    <Pagination
                                        count={Math.ceil(mtnReports.length / itemsPerPage)}
                                        page={page}
                                        onChange={(e, val) => setPage(val)}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                    />
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Vodacom"
                                />
                                <CardContent>
                                    <List>
                                        {
                                            vcReports && vcReports
                                            .slice((vcPage -1) * itemsPerPage, vcPage * itemsPerPage)
                                            .map(r => (
                                                <ListItem key={r.id} divider>
                                                    <ListItemText>{r.year} - {r.month} ({moment(r.timestamp.toDate()).fromNow()})</ListItemText>
                                                    <ListItemSecondaryAction>
                                                        {
                                                            r.json
                                                            ?
                                                            <Link to={`/dealer-reports/connections-report/vodacom/connection/${r.id}`}>
                                                                <IconButton>
                                                                    <ArrowForward />
                                                                </IconButton>
                                                            </Link>
                                                            :
                                                            <Tooltip title="Report Still Running">
                                                                <IconButton><HourglassEmpty /></IconButton>
                                                            </Tooltip>
                                                        }
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    
                                </CardContent>
                                <CardActions>
                                    <Pagination
                                        count={Math.ceil(vcReports.length / itemsPerPage)}
                                        page={page}
                                        onChange={(e, val) => setVcPage(val)}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                    />
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                    { error ? <span className="sim-error">{error}</span> : null }
                </CardContent>
            </Card>
        </div>
    )
}
