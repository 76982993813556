import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import React, { useState } from 'react'

export default function CustomSimDataTable({ rows, columns, network, type, reportId, month, year }) {

    const [search, setSearch] = useState(null)
    
    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(rows.length / itemsPerPage)
    );
    
    return (
        <div>
            <TextField fullWidth variant="outlined" label='Search Dealer Name' onChange={e => setSearch(e.target.value.toLowerCase())}/>
            <br />
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            { columns && columns.map((c, i) => (<TableCell key={i}>{c.headerName}</TableCell>)) }
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            search !== null || ''
                            ?
                            rows && rows
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map((r,i) => {
                                if (
                                    r.dealerName.toLowerCase().includes(search)
                                ) {
                                    return(
                                        <TableRow key={i}>
                                            <TableCell>{r.dealerName}</TableCell>
                                            <TableCell>{r.phone}</TableCell>
                                            <TableCell>{r.count}</TableCell>
                                            <TableCell>
                                                <Button
                                                    endIcon={<ArrowForward />}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    href={`/json-dealer-report/${network}/${type}/${reportId}/${r.id}/${month}/${year}`}
                                                >
                                                    View Agents
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })
                            :
                            rows && rows
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map((r,i) => (
                                <TableRow key={i}>
                                    <TableCell>{r.dealerName}</TableCell>
                                    <TableCell>{r.phone}</TableCell>
                                    <TableCell>{r.count}</TableCell>
                                    <TableCell>
                                        <Button
                                            endIcon={<ArrowForward />}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            href={`/json-dealer-report/${network}/${type}/${reportId}/${r.id}/${month}/${year}`}
                                        >
                                            View Agents
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(rows.length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
            /> 
        </div>
    )
}
