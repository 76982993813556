import { Button, Card, CardContent, Typography } from '@material-ui/core'
import React from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import { Link } from 'react-router-dom'
import { ArrowForward } from '@material-ui/icons'

export default function ResultLink({ searchId }) {
    const { GetSearchResults } = useDb()
    const results = GetSearchResults(searchId)

    return (
        <div>
            Results:
            <Card>
                <CardContent>
                    {
                        results && results.length > 0 
                        ?
                        <Link to={`/check-stock-result/${searchId}`}>
                            <Button color="primary" variant="contained" startIcon={<ArrowForward />}>Go To Result</Button>
                        </Link>
                        :
                        <Typography>Loading ...</Typography>
                    }
                </CardContent>
            </Card>
        </div>
    )
}
