import { faBoxes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { AddBox, Home, Replay, SimCard } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps, textFieldProps } from '../../shared/constants'

export default function DealerAllocateBoxes() {
    const { network } = useParams()
    const { SubGetChildDealers, findBoxOrBrick, allocateBoxes } = useDb()
    const dealers = SubGetChildDealers()
    const history = useHistory()

    // Action State
    const [dealer, setDealer] = useState(null)
    const [boxNumber, setBoxNumber] = useState(null)
    const [boxes, setBoxes] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)

    // Box Functions
    const addBox = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(null)
        setBoxNumber(null)
        if(boxes.includes(boxNumber)) {
            setError('You have already added this box.')
            setLoading(false)
            return
        }
        if(boxes.length === 25) {
            setError('Maximum Number of boxes (25) for this allocation, please split allocation or consider allocating entire invoice.')
            setLoading(false)
            return
        }
        try {
            await findBoxOrBrick(network, 'boxes', boxNumber).then(() => {
                setLoading(false)
                setBoxes([
                    ...boxes,
                    boxNumber
                ])
                setBoxNumber(null)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const removeBox = (boxNumber) => {
        const nBoxes = boxes.filter(i => i !== boxNumber)
        setBoxes(nBoxes)
    }

    const handleChange = (e) => {
        setBoxNumber(e.target.value)
    }

    const handleAllocate = async () => {
        console.log('BOXES: ', boxes)
        console.log('DEALER: ', dealer)
        setError(null)
        setLoading(true)
        try {
            await allocateBoxes(network, boxes, dealer).then(() => {
                setConfirmOpen(true)
            })
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    const restart = () => {
        setConfirmOpen(false)
        window.location.reload()
    }

    const headHome = () => {
        setConfirmOpen(false)
        setDealer(null)
        setBoxNumber(null)
        setBoxes(null)
        history.push('/')
    }

    return (
        <div>
            <h1>Allocate Boxes: {network}</h1>
            <Select
                {...selectFieldProps}
                placeholder="Select Dealer"
                onChange={v => setDealer(v)}
                options={dealers}
                className="sim-select"
            />  
            {
                dealer
                ?
                <Card>
                    <form onSubmit={addBox}>
                        <CardContent>
                            <TextField 
                                value={!boxNumber ? '' : boxNumber}
                                onChange={handleChange}
                                {...textFieldProps}
                                label="Type/Scan Box Number"
                            />
                        </CardContent>
                        <CardActions>
                            <Button disabled={ loading || !boxNumber } type="submit" startIcon={<AddBox />} color="primary" variant="contained">Add Box</Button>
                            { error ? <span className="sim-error">{error}</span> : null }
                        </CardActions>
                    </form>
                </Card>
                :
                null
            }
            <br/>
            <Card>
                <CardHeader
                    title="Boxes to Allocate"
                    avatar={
                        <Avatar>
                            <FontAwesomeIcon icon={faBoxes} />
                        </Avatar>
                    }
                />
                <CardContent>
                    {
                        boxes && boxes.map(box => (
                            <Chip style={{ margin: 2 }} key={box} label={box} onDelete={() => removeBox(box)}/>
                        ))
                    }
                </CardContent>
                <CardActions>
                    <Button onClick={handleAllocate} disabled={ loading || boxes.length < 1 } fullWidth color="primary" size="large" variant="contained" startIcon={<SimCard />}>Allocate Boxes</Button>
                </CardActions>
            </Card>

            <Dialog open={confirmOpen} maxWidth="sm" fullWidth onClose={() => setConfirmOpen(false)}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    These boxes have successfully been allocated. What would you like to do next? 
                </DialogContent>
                <DialogActions>
                    <Button onClick={restart} startIcon={<Replay />} color="primary" variant="contained">Allocate more {network}</Button>
                    <Button onClick={headHome} startIcon={<Home />} color="primary" variant="contained">Go Home</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
