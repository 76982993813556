import { Card, CardMedia, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import cellC from '../../../img/cellC.jpeg'
import mtn from '../../../img/mtn.png'
import vodacom from '../../../img/vodacom.png'
import telkom from '../../../img/telkom.png'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        paddingTop: '70.25%', 
    },
}))

export default function DealerAllocateStock() {
    const classes = useStyles()
    return (
        <div>
            <h1>Allocate Stock</h1>
            <Grid container spacing={3}>
                <Grid item xs={6} md={3}>
                    <Link to={`/allocate-stock/mtn/select`}>
                        <Card>
                            <CardMedia
                                className={classes.media}
                                image={mtn}
                            />
                        </Card>
                    </Link>
                    
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <CardMedia
                            className={classes.media}
                            image={vodacom}
                        />
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <CardMedia
                            className={classes.media}
                            image={telkom}
                        />
                    </Card>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Card>
                        <CardMedia
                            className={classes.media}
                            image={cellC}
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
