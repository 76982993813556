import { AuthProvider } from '../contexts/AuthContext';
import Wrapper from './Wrapper';

function App() {
  return (
    <div className="App">
        <AuthProvider>
          <Wrapper />
        </AuthProvider>
    </div>
  );
}

export default App;
