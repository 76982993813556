import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Grid, Typography } from '@material-ui/core'
import { ArrowBack, BarChartSharp, Folder, People, PersonAdd } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import FileUploader from '../../shared/FileUploader'
import DealerFiles from '../DealerFiles'
import ChangeParent from './ChangeParent'
import DealerStockReports from './DealerStockReports'

export default function RDealerDetail() {
    const { dealerId } = useParams()
    const history = useHistory()
    const { getDealer, GetDealerFiles, attachFileToDealer } = useDb()
    const { currentUser } = useAuth()
    const [dealer, setDealer] = useState()
    const files = GetDealerFiles(dealerId)
    const [changeParent, setChangeParent] = useState(false)
    const [state, setState] = useState({
        loading: false,
        error: null
    })

    useEffect(async () => {
        const d = await getDealer(dealerId)
        setDealer(d)
        return 
    }, [])

    const getFile = async (file) => {
        setState({
            loading: true,
            error: null
        })
        try {
            await attachFileToDealer(dealer.id, file).then(() => {
                setState({
                    loading: false,
                    error: null
                })
            })
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }

    return !dealer ? <CircularProgress /> : (
        <div>
            <Container>
                <h1>{dealer.contactName} {dealer.contactSurname}</h1>
                <div className="back-link">
                    <Button color="primary" startIcon={<ArrowBack />} onClick={() => history.goBack()}>Back</Button>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Card>
                            <CardContent>
                                <Typography>ID Number: ???</Typography>
                                <Typography>Phone Number: {dealer.phone}</Typography>
                                <Typography>Email: {dealer.email}</Typography>
                                <br/>
                                <Typography>Street Address: {dealer.street}</Typography>
                                <Typography>Suburb: {dealer.suburb}</Typography>
                                <Typography>City: {dealer.city}</Typography>
                                <Typography>Province: {dealer.province}</Typography>
                                <br/>
                                <Typography>Dealer Type: {dealer.dealerType}</Typography>
                                
                            </CardContent>
                            <CardActions>
                                <Link to={`/dealer-agents/${dealerId}`}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        startIcon={<People />}
                                    >
                                        View All Agents
                                    </Button>
                                </Link>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<PersonAdd />}
                                    onClick={() => setChangeParent(true)}
                                >
                                    Change Parent
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader
                                title={'Attachments'}
                                avatar={<Avatar><Folder /></Avatar>}
                            />
                            <CardContent>
                                {
                                    currentUser && currentUser.level <= 1 
                                    ?
                                        <>
                                            <DealerFiles files={files} />
                                            <FileUploader sendFile={getFile} />
                                        </>
                                    :
                                    null
                                }
                            </CardContent>
                        </Card>
                        <br/>
                    </Grid>
                </Grid>
                <DealerStockReports dealerId={dealerId}/>
            </Container>

            <ChangeParent
                open={changeParent}
                close={() => setChangeParent(false)}
                dealerId={dealer.id}
                level={dealer.rank}
            />
        </div>
    )
}
