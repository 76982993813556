import { faSimCard, faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Settings() {
    return (
        <div>
            <h1>Settings</h1>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title={'Dealers'}
                            avatar={<FontAwesomeIcon icon={faUsers}/>}
                        />
                        <CardActions>
                            <Link to={'/settings/levels'}>
                                <Button color={'primary'}>
                                    Levels
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title={'RICA'}
                            avatar={<FontAwesomeIcon icon={faSimCard}/>}
                        />
                        <CardActions>
                            <Link to={'/settings/rica'}>
                                <Button color={'primary'}>
                                    RICA Settings
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
                {/* <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title={'Users'}
                            avatar={<FontAwesomeIcon icon={faUserCheck}/>}
                        />
                        <CardActions>
                            <Link to={'/settings/users'}>
                                <Button color={'primary'}>
                                    User Settings
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid> */}
            </Grid>
        </div>
    )
}
