import React, { useEffect, useState, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import logo from '../../img/chadez.png'
import { ArrowForward } from '@material-ui/icons';
import { authFunc } from '../../firebase'
import { useDb } from '../../contexts/DatabaseContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        We Manage Your Site (Pty) Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PhoneLogin() {
    const classes = useStyles();
    const { currentUser, loginWithPhone, publicDealerRequestCheck } = useAuth();
    const [state, setState] = useState({})
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory()
    const [otpView, setOtpView] = useState(false)

    // New Fireship Method
    const [recaptcha, setRecaptcha] = useState(null)
    const element = useRef(null)
    const [confirmationResult, setConfirmationResult] = useState(null)
    const [verificationCode, setVerificationCode] = useState('')

    const handleChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
      if(!recaptcha) {
        const verifier = new authFunc.RecaptchaVerifier(element.current, {
          size: 'invisible',
        })
        verifier.verify().then(() => setRecaptcha(verifier))
      }
    })

    

    async function handleSubmit() {
        // TODO: First Check to see if dealer exists, if not throw 
        try {
          setError(null);
          setLoading(true);
            await publicDealerRequestCheck(state.phone)
            await loginWithPhone(state.phone, recaptcha).then((confirmation) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              console.log(confirmation)
              setConfirmationResult(confirmation)
              setOtpView(true)
            })
            // history.push('/')
        } catch (err) {
          setError(err.message);
        }
        setLoading(false);
    }

    async function handleOtpSubmit() {
      setError(null);
      setLoading(true);
      try {
        await confirmationResult.confirm(state.otp)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          history.push('/')
          // ...
        })
      }
      catch(err) {
        console.log(err.message)
      }
    }

    

  return (
    <Container component="main" maxWidth="xs">
    <div ref={element}></div>
      <Helmet>
          <title>Login</title>
      </Helmet>
      <CssBaseline />
      {
        !otpView
        ?
        <div className={classes.paper}>
          <img src={logo} width={200} />
          <br/>
          <Typography component="h1" variant="h5">
            Sign in with Phone
          </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Phone Number"
              name="phone"
              autoFocus
              type="text"
              onChange={handleChange}
            />
            <br />
            <span style={{ color: 'red' }}>{error}</span>
            <br />
            <span style={{ color: 'blue' }}>{currentUser?.email}</span>
            <Button
              onClick={handleSubmit}
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<ArrowForward />}
            >
              Next
            </Button>
            
        </div>
        :
        <div>
          <Typography component="h1" variant="h5">
          Enter OTP 
        </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Enter OTP"
            name="otp"
            autoFocus
            onChange={handleChange}
          />
          <br />
          <span style={{ color: 'red' }}>{error}</span>
          <br />
          <span style={{ color: 'blue' }}>{currentUser?.email}</span>
          <Button
            onClick={handleOtpSubmit}
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit
          </Button>
        </div>
      }
      
      <Box mt={8}>
        <Copyright />
      </Box>
      
    </Container>
  );
}
