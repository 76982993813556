import { Button, IconButton } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import React from 'react'
import { useParams, useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import SimDataGrid from '../../shared/data-grid/SimDataGrid'

export default function DealerAgents() {
    const { dealerId } = useParams()
    const history = useHistory()
    const { GetDealerAgents } = useDb()
    const agents = GetDealerAgents(dealerId)

    const rows = agents && agents.map(a =>({
        id: a.id,
        dealerName: a.dealerName,
        phone: a.phone,
        street: a.street,
        city: a.city,
        suburb: a.suburb
    }))

    const columns = [
        { field: 'dealerName', headerName: 'Name' , width: 200 },
        { field: 'phone', headerName: 'Phone' , width: 150 },
        { field: 'street', headerName: 'Street' , width: 200 },
        { field: 'suburb', headerName: 'Suburb' , width: 200 },
        { field: 'city', headerName: 'City' , width: 200 },
        { 
            field: 'action', 
            headerName: 'Detail', 
            width: 140,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                        <Link to={`/dealer-detail/${params.row.id}`}>
                            <IconButton>
                                <ArrowForward />
                            </IconButton>
                        </Link>
                     </div>
                );
            }
        },
    ]


    return (
        <div>
            <div className="back-link">
                <Button
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                    color="primary"
                >
                    Back
                </Button>
            </div>
            <SimDataGrid
                data={rows}
                columns={columns}
            />
        </div>
    )
}
