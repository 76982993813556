import { CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDb } from '../../contexts/DatabaseContext'

export default function IwanConsole() {
    const { iwanGetBrickAllocationKey } = useDb()
    const [loading, setLoading] = useState(true)
    const [row, setRow] = useState([])
    useEffect( async () => {
        setLoading(true)
        const data = await iwanGetBrickAllocationKey('XlSuOJGXL9UDQIPss46kGR1g97l2', [
            '20210324000181',
            'CJA0892055',
            'CJA0892052'
        ])
        setRow(await data.map(d => ({
            id: d.box,
            box: d.box,
            allocationKey: d.allocationKey
        })))
        setLoading(false)
    }, [])

    const columns = [
        {
            field: 'box', 
            headerName: 'Box',
            width: 300
        },
        {
            field: 'allocationKey',
            headerName: 'Allocation Key',
            width: 300
        }
    ]

    console.log(row)

    return (
        <div>
           {
               loading
               ?
               <CircularProgress />
               :
               <DataGrid autoHeight columns={columns} rows={row} />
           }
        </div>
    )
}
