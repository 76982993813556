import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper } from '@material-ui/core'
import { Pagination } from '@material-ui/lab';
import React, { useState } from 'react'

export default function DealerFiles({ files }) {

    // Pagination
    const itemsPerPage = 5; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(files.length / itemsPerPage)
    );

    return (
        <>
            <List>
                {
                    files && files.map(file => {
                        return (
                            <ListItem key={file.id} component={Paper} style={{ marginBottom: 5}}>
                                <ListItemText>{file.fileName}</ListItemText>
                                <ListItemSecondaryAction>
                                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                                        <IconButton>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </IconButton>
                                    </a>
                                </ListItemSecondaryAction>
                            </ListItem>
                        )
                    })
                }
            </List>
            <Pagination
                count={Math.ceil(files.length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
            /> 
        </>
    )
}
