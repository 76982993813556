import React from 'react'
import { ImporterField } from 'react-csv-importer'

export default function VodacomSchema() {
    return (
        <>
            <ImporterField name="serial_number" label="Sim Number (ICCID)" />
            <ImporterField name="carton_id" label="Carton ID'" />
            <ImporterField name="ref_box_number" label="Brick Number (Ref Box)" />
        </>
    )
}
