import { Button, Card, CardActions, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import SubCreateDealer from './SubCreateDealer'
import { Pagination } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function SubDealerManage() {
    const [open, setOpen] = useState(false)
    const { SubGetChildDealers } = useDb()
    const dealers = SubGetChildDealers()
    
    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(dealers.length / itemsPerPage)
    );

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card>
                        <CardContent>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Level</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dealers && dealers
                                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                                            .map(d => {
                                                return (
                                                    <TableRow key={d.id}>
                                                        <TableCell>{d.contactName}</TableCell>
                                                        <TableCell>{d.contactSurname}</TableCell>
                                                        <TableCell>{d.rank}</TableCell>
                                                        <TableCell>
                                                            {/* <Link to={`/manage-dealers/${d.id}`}>
                                                                <FontAwesomeIcon icon={faArrowRight} />
                                                            </Link> */}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                        <CardActions>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Pagination
                                        count={Math.ceil(dealers.length / itemsPerPage)}
                                        page={page}
                                        onChange={(e, val) => setPage(val)}
                                        defaultPage={1}
                                        color="primary"
                                        size="large"
                                        showFirstButton
                                        showLastButton
                                    /> 
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Button onClick={() => setOpen(true)} variant={'contained'} color={'primary'} startIcon={<Add />}>Add Dealer</Button>
                                </Grid>
                            </Grid>
                            
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            
            <SubCreateDealer open={open} closeDialog={() => setOpen(false)}/>
        </div>
    )
}
