import React from 'react'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import reports from '../../../img/reports.svg'

export default function DealerReports() {
    return (
        <div>
            <h1>Reports</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader 
                            title={'Reports'}
                            avatar={<FontAwesomeIcon icon={faChartBar}/>}
                        />
                        <CardContent className="sim-card-content">
                            <img src={reports} width="100%" height="100"/>
                        </CardContent>
                        <CardActions>
                            <Link to='/dealer-reports/connections'><Button color="primary">Connections</Button></Link>
                            <Link to='/dealer-reports/activations'><Button color="primary">Activations</Button></Link>
                            <Link to='/dealer-reports/rica-report'>RICA</Link>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
