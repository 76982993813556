import { Avatar, Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core'
import { BarChartSharp } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import DealerStockReport from './DealerStockReport'

export default function DealerStockReports({ dealerId }) {
    const { GetDealerStockReports } = useDb()
    const mtnReports = GetDealerStockReports(dealerId, 'mtn')

    return (
        <Card>
            <CardHeader 
                title="Efficiency Reports"
                avatar={
                    <Avatar>
                        <BarChartSharp />
                    </Avatar>
                }
            />
            <CardContent>
                {
                    mtnReports && mtnReports.map(r => (
                        <DealerStockReport report={r} key={r.id} />
                    ))
                }
            </CardContent>
            <CardActions>
                <Link to={`/agents-efficiency/${dealerId}/mtn`}>
                    <Button
                        color="primary"
                        variant="contained"
                    >
                        View Agent Efficiency (MTN)
                    </Button>
                </Link>
            </CardActions>

            {/* TODO: Vodacom  */}
            {/* TODO: Telkom  */}
        </Card>
    )
}
