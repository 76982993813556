import React from 'react'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import { Card, CardContent } from '@material-ui/core'

export default function ReportTree({ report, type, total }) {
    console.log(report)

    const renderTree = (nodes) => (
        
        type === 'Activations'
        ?
        <TreeItem key={nodes.id} nodeId={nodes.id} label={`${nodes.dealerName} - ${type}: ${nodes.activations ? nodes.activations : 0}`}>
          {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
        :
        <TreeItem key={nodes.id} nodeId={nodes.id} label={`${nodes.dealerName} - ${type}: ${nodes.connections ? nodes.connections : 0}`}>
          {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    )

    return (
        <div>
            <Card>
                <CardContent>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        // defaultExpanded={['root']}
                    >
                        <TreeItem label={`Total: ${total}`} />
                        <TreeItem label={`Allocated:`} />
                        {
                            report.map(r => {
                                return renderTree(r)
                            })
                        }
                        
                    </TreeView>
                </CardContent>
            </Card>

            
        </div>
    )
}
