import { Avatar, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core'
import { SimCard } from '@material-ui/icons'
import React from 'react'
import BrickBoxResult from './BrickBoxResult'

export default function SearchResult({ result, simOnly }) {
    
    if(simOnly) {
        return (
            <div>
                <CardHeader
                    avatar={<Avatar><SimCard /></Avatar>}
                    title={result.id}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Typography>Connected?: { result.connected ? 'Yes' : 'No' }</Typography>
                        {
                            result.connected
                            ?
                            <div>
                                <Typography>Connection Month: { result.connectionMonth }</Typography>
                                <Typography>Connection Year: { result.connectionYear }</Typography>
                            </div>
                            :
                            null
                        }
                        <Typography>Activated?: { result.activated ? 'Yes' : 'No' }</Typography>
                        {
                            result.activated
                            ?
                            <div>
                                <Typography>Activation Month: { result.activationMonth }</Typography>
                                <Typography>Activation Year: { result.activationYear }</Typography>
                            </div>
                            :
                            null
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                History:
                            </CardContent>
                        </Card>
                        <br/>
                        <Card>
                            <CardContent>
                                Allocation:
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
    else {
        return (
            <div>
                <BrickBoxResult data={result} />
            </div>
        )
    }
    
}
