import { Avatar, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@material-ui/core'
import { ArrowDownward, LocalShipping, SimCard } from '@material-ui/icons'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHome, faWindowClose } from '@fortawesome/free-solid-svg-icons'

export default function AllocateBox() {
    const [network, setNetwork] = useState(null)
    const [boxArray, setBoxArray] = useState([])
    const [boxNumber, setBoxNumber] = useState('')
    const [dealer, setDealer] = useState(null)
    const [allocation, setAllocation] = useState({
        vodacom: [],
        cellC: [],
        telkom: [],
        mtn: []
    })
    const [charLength, setCharLength] = useState(99)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const history = useHistory()
    const [boxIdName, setBoxIdName] = useState(null)

    const [firstLoadDone, setFirstLoadDone] = useState(false)
    const [user, setUser] = useState(null)
    // const [dealers, setDealers] = useState([])

    const [errorDialog, setErrorDialog] = useState(false)
    const [check, setCheck] = useState(null)

    const { currentUser, userLevel } = useAuth()
    const { allocateBigQueryBoxBrick, getCurrentUserData, SubGetChildDealers, getChildDealers, GetAllDealers, CheckIfBoxExists } = useDb()
    const dealers = userLevel <= 1 ? GetAllDealers() : SubGetChildDealers()

    const networks = [
        { value: 'mtn', label: 'MTN'},
        { value: 'vodacom', label: 'Vodacom'},
        { value: 'telkom', label: 'Telkom'}
    ];

    const dealerOptions = dealers.map((d) => ({
        value: d,
        label: `${d.contactName} ${d.contactSurname} - (Level ${d.rank})`
    }))

    const handleErrorDialogClose = () => {
        setErrorDialog(false)
        setLoading(false)
    }

    const handleAdd = async () => {
        const boxArray = allocation[network]
        setLoading(true)
        setError(null)
        try {
            if(boxArray.includes(boxNumber)) {
                throw({
                    message: "This box has already been added to the allocation."
                })
            }
            const res = await CheckIfBoxExists(network, boxIdName, boxNumber)
            if (res === true) {
                boxArray.push(boxNumber)
                setAllocation({
                    ...allocation,
                    [network]: boxArray
                })
                setLoading(false)
                setBoxNumber('')
            } else if(res === false) {
                setErrorDialog(true)
                setCheck('This box is already allocated to another Agent')
            }
        } catch (err) {
            setError(err.message)
            setLoading(false)
            setBoxNumber('')
        }    
    }

    const handleReAllocation = () => {
        const boxArray = allocation[network]
        setLoading(true)
        setError(null)
        try {
            boxArray.push(boxNumber)
            setLoading(false)
            setBoxNumber('')
            setErrorDialog(false)
        }
        catch (err) {
            setError(err.message)
            setLoading(false)
            setBoxNumber('')
        }
    }

    const handleNetworkChange = (network) => {
        if(network === 'vodacom') {
            setCharLength(8)
            setBoxIdName('boxes')
        }
        else if(network === 'mtn') {
            setCharLength(10)
            setBoxIdName('boxes')
        }
        else if(network === 'cellC') {
            setCharLength(16)
            setBoxIdName('boxes')
        }
        else if(network === 'telkom') {
            setCharLength(13)
            setBoxIdName('boxes')
        }
        setNetwork(network)
    }

    // Confirmation Dialog
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [sims, setSims] = useState(0)
    const handleConfirmClose = () => {
      setConfirmOpen(false)
      setSims(0)
      history.push('/')
    }
    const handleConfirmOpen = (simAmt) => {
      setSims(simAmt)
      setConfirmOpen(true)
    }

    const handleSubmit = async () => {
        setLoading(true)
        await allocateBigQueryBoxBrick('boxes', allocation, dealer) 
        .then(simsAmt => {
            setLoading(false)
            handleConfirmOpen(simsAmt)
        })
        .catch((err) => {
            setError(err)
            setLoading(false)
        })
    }

    return (
        <div>
            <h1>Allocate Boxes</h1>
            { error ? <Typography style={{ color: 'red'}}>{error}</Typography> : null }
            <Select
                options={dealerOptions}
                onChange={(val) => setDealer(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Dealer'}
            />
            <br/>
            <Select
                options={networks}
                onChange={(val) => handleNetworkChange(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Network'}
            />
            <br/>
            {
                dealer
                ?
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={9} lg={9}>
                        <TextField fullWidth variant="outlined" label={'Scan / Type Box Number'} onChange={(e) => setBoxNumber(e.target.value)} value={boxNumber} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Button onClick={handleAdd} disabled={boxNumber.length !== charLength || loading } size="large" startIcon={<ArrowDownward />} variant="contained" color="primary">Add to Allocation</Button>
                    </Grid>
                </Grid>
                :
                null
            }
            <br/>
            {
                dealer
                ?
                <Card>
                    <CardHeader
                        avatar={<Avatar><LocalShipping /></Avatar>}
                        title={'Boxes To Allocate:'}
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card>
                                    <CardHeader
                                        title={'Vodacom'}
                                    />
                                    <CardContent>
                                        {
                                            allocation && allocation.vodacom.map((box) => {
                                                return (
                                                    <li key={box}>{box}</li>
                                                )
                                            })
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card>
                                    <CardHeader
                                        title={'MTN'}
                                    />
                                    <CardContent>
                                        {
                                            allocation && allocation.mtn.map((box) => {
                                                return (
                                                    <li key={box}>{box}</li>
                                                )
                                            })
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card>
                                    <CardHeader
                                        title={'CellC'}
                                    />
                                    <CardContent>
                                        {
                                            allocation && allocation.cellC.map((box) => {
                                                return (
                                                    <li key={box}>{box}</li>
                                                )
                                            })
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Card>
                                    <CardHeader
                                        title={'Telkom'}
                                    />
                                    <CardContent>
                                        {
                                            allocation && allocation.telkom.map((box) => {
                                                return (
                                                    <li key={box}>{box}</li>
                                                )
                                            })
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <br/>
                        { error ? <Typography style={{ color: 'red'}}>{error}</Typography> : null }
                        <Button disabled={ loading } fullWidth onClick={handleSubmit} size="large" startIcon={<SimCard />} variant="contained" color="primary">Allocate SIMS</Button>
                    </CardContent>
                </Card>
                :
                null
            }

            <Dialog open={confirmOpen} maxWidth="sm" fullWidth onClose={handleConfirmClose}>
              <DialogContent>
                <Typography>
                  <b>Allocation Result:</b> {sims} Boxes Allocated
                </Typography> 
              </DialogContent>
              <DialogActions>
                <Button disabled={loading} onClick={handleConfirmClose} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faHome}/>}>Home</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={errorDialog} maxWidth={'sm'} fullWidth onClose={handleErrorDialogClose} >
                <DialogContent>
                    <h2>Are you Sure?</h2>
                    <Typography variant='h6' color='error' >{check}</Typography>
                    <Typography>Are you sure you want to re-allocate this box</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReAllocation} variant="contained" color="primary" startIcon={<FontAwesomeIcon icon={faCheck}/>}>Yes</Button>
                    <Button onClick={handleErrorDialogClose} variant="contained" color="secondary" startIcon={<FontAwesomeIcon icon={faWindowClose}/>}>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
