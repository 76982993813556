import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Select from 'react-select'
import { useDb } from '../../../contexts/DatabaseContext'
import { selectFieldProps } from '../../shared/constants'

export default function ChangeParent({ open, close, dealerId, level }) {
    const { GetDealersOfLevel, updateDealer } = useDb()
    const dealers = GetDealersOfLevel(level-1)

    const [newParent, setNewParent] = useState()
    const [state, setState] = useState({
        loading: false,
        error: null
    })
    
    const handleClose = () => {
        setNewParent(null)
        close()
    }

    const handleSelect = (v) => {
        console.log(v.value)
        setNewParent(v.value)
    }

    const handleSubmit = async () => {
        setState({
            loading: true, 
            error: null
        })
        try {
            await updateDealer(dealerId, {
                parentDealer: newParent
            })
            handleClose()
        }
        catch(err) {
            setState({
                loading: false,
                error: err.message
            })
        }
    }   

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle>Change Parent</DialogTitle>
            <DialogContent>
                <Select
                    options={dealers}
                    className="sim-select"
                    {...selectFieldProps}
                    onChange={handleSelect}
                />
            </DialogContent>
            <DialogActions>
                {
                    state.error && <Typography color="secondary">{state.error}</Typography>
                }
                <Button
                    color="primary"
                    disabled={!newParent}
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
