import React, { useState } from 'react'
import { useDb } from '../../contexts/DatabaseContext'
import moment from 'moment'
import { Button, Card, CardActions, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import SimDataGrid from '../shared/data-grid/SimDataGrid'
import { Link, useHistory } from 'react-router-dom'
import { ArrowBack } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'

export default function AllNotifications() {
    const { GetAllNotifs } = useDb()
    const notifs = GetAllNotifs()
    const history = useHistory()

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(notifs.length / itemsPerPage)
    );

    return (
        <div>
            <div className="back-link">
                <Button
                    color="primary"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <Card>
              <CardContent>
                <h1>All Notifications</h1>
                <TableContainer component={Paper} >
                  <Table size="small" >
                    <TableHead>
                      <TableRow>
                        <TableCell>Occurred</TableCell>
                        <TableCell>Dealer</TableCell>
                        <TableCell>Event</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        notifs && notifs
                        .slice((page -1) * itemsPerPage, page * itemsPerPage)
                        .map(n => {
                          return(
                            <TableRow key={n.id}>
                              <TableCell>{moment(n.createdAt.toDate()).fromNow()}</TableCell>
                              <TableCell>{n.author.contactName ? n.author.contactName : n.author.firstName}</TableCell>
                              <TableCell>{n.type}</TableCell>
                              <TableCell>
                                {
                                  n.allocationId 
                                  ?
                                  <Link to={`/allocation-detail/${n.allocationId}`}>
                                    <Button 
                                        variant="contained"   
                                        color="primary"
                                        size="small" 
                                    >
                                        View Allocation
                                    </Button>
                                  </Link>
                                  :
                                  ''
                                }
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              <CardActions>
                <Pagination
                  count={Math.ceil(notifs.length / itemsPerPage)}
                  page={page}
                  onChange={(e, val) => setPage(val)}
                  defaultPage={1}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                /> 
              </CardActions>
            </Card>
        </div>
    )
}
