import { Button, Card, CardActions, CardContent, Grid, LinearProgress, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useDb } from '../../contexts/DatabaseContext'
import { networks, selectFieldProps, years, months } from '../shared/constants'
import SimsTable from '../sim-results/SimsTable'

export default function DealerStock() {
    const { id } = useParams()
    const history = useHistory()
    const { getStockAllocatedTo } = useDb()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [network, setNetwork] = useState(null)
    const [result, setResult] = useState(null)

    const handleSearch = async () => {
        setLoading(true)
        setError(null)
        setResult(null)
        try {
            const r = await getStockAllocatedTo(network, id)
            setResult(r)
            setLoading(false)
            console.log(r)
        }
        catch(err) {
            setError(err.message)
            setLoading(false)
        }
    }

    return (
        <div>
            <h1>Check Dealer Stock</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                    <Card>
                        <CardContent>
                            <Select
                                {...selectFieldProps}
                                placeholder="Select Network ..."
                                options={networks}
                                onChange={v => setNetwork(v.value)}
                            />
                            { error ? <span className="sim-error">{error}</span> : null }
                        </CardContent>
                        <CardActions>
                            <Button disabled={!network || loading } color="primary" variant="contained" fullWidth onClick={handleSearch}>Get Stock</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    <Card>
                        <CardContent>
                            { loading ? <LinearProgress /> : null }
                            {
                                result 
                                ?
                                <>
                                    <Typography>SIMS found: {result.length}</Typography>
                                    <SimsTable data={result} />
                                </>
                                :
                                null
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
