import React from 'react'
import { useParams } from 'react-router-dom'

export default function RCheckDealerStock() {
    const { dealerId } = useParams()
    
    return (
        <div>
            
        </div>
    )
}
