import { faInfo, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Card, CardHeader } from '@material-ui/core'
import React from 'react'
import moment from 'moment'

export default function Notification({n}) {
    var icon = <FontAwesomeIcon icon={faInfo}/>;
    if(n.type === 'Created a new agent') {
        icon = <FontAwesomeIcon icon={faUserPlus}/>
    }
    if(n.type === 'Created a new dealer') {
        icon = <FontAwesomeIcon icon={faUserPlus}/>
    }
    return (
        <>
                <CardHeader
                    title={`${n.author.contactName || n.author.firstName} ${n.author.contactSurname || n.author.lastName} ${n.type}`}
                    subheader={moment(n.createdAt.toDate()).fromNow()}
                    avatar={<Avatar>{icon}</Avatar>}
                />
        </>
    )
}
