import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import { CreditCard, Edit, Search } from '@material-ui/icons'
import { Pagination } from '@material-ui/lab'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import SimDataGrid from '../shared/data-grid/SimDataGrid'
import EditDealer from './EditDealer'
import ManageCard from './ManageCard'

export default function DealerList({ dealerLevel }) {
    const { GetDealerList } = useDb()
    const dealers = GetDealerList(dealerLevel)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [widget, setWidget] = useState(null)
    const [search, setSearch] = useState(null) 

    // Pagination
    const itemsPerPage = 10; // <- Not state but related to below pagination
    const [page, setPage] = useState(1);
    const [noOfPages] = useState(
        Math.ceil(dealers.length / itemsPerPage)
    );

    const openCardDialog = (d) => {
        setWidget(<ManageCard dealer={d} closeDialog={closeDialog}/>)
        setDialogOpen(true)
    }

    const closeDialog = () => {
        setDialogOpen(false)
        setTimeout(500)
        setWidget(null)
    }

    // Edits 
    const [editOpen, setEditOpen] = useState(false)
    const [selectedDealer, setSelectedDealer] = useState(false)
    const handleEdit = (d) => {
        setSelectedDealer(d)
        setEditOpen(true)
    }

    const rows = dealers && dealers.map(d => ({
        id: d.id,
        dealerName: `${d.contactName } ${d.contactSurname}`,
        email: d.email,
        phone: d.phone,
        city: d.city,
        province: d.province,
        dealerId: d.contactIdNo
    }))

    const columns = [
        { field: 'dealerName', headerName: 'Dealer Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 200 },
        { field: 'dealerId', headerName: 'ID/Passport', width: 200 },
        { field: 'city', headerName: 'City', width: 200 },
        { field: 'province', headerName: 'Province', width: 200 },
        { 
            field: 'action',
            headerName: 'Action', 
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        <ButtonGroup>
                            <IconButton color="primary">
                                <Edit onClick={() => handleEdit(params.row)}/>
                            </IconButton>
                            <IconButton color="primary">
                                <CreditCard onClick={() => openCardDialog(params.row)}/>
                            </IconButton>
                            <Link to={`/dealer-detail/${params.row.id}`}>
                                <IconButton color="primary">
                                    <Search />
                                </IconButton>
                            </Link>
                        </ButtonGroup>
                    </div>
                );
            }
        },
    ]
    
    return (
        <>
            <SimDataGrid
                data={rows}
                columns={columns}
            />
            <TextField fullWidth variant="outlined" label='Search Dealers' onChange={e => setSearch(e.target.value.toLowerCase())}/>
            <br />
            <br />
            {/* <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name/Trading Name</TableCell>
                            <TableCell>Contact Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>City</TableCell>
                            <TableCell>Province</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            search !== null || ''
                            ?
                            dealers && dealers
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map((dealer) => {
                                if(
                                    dealer.dealerName ? dealer.dealerName.toLowerCase().includes(search) : dealer.contactName.toLowerCase().includes(search) ||
                                    // dealer.dealerName.toLowerCase().includes(search) ||
                                    // dealer.contactName.toLowerCase().includes(search) ||
                                    dealer.contactSurname ? dealer.contactSurname.toLowerCase().includes(search) : null ||
                                    // dealer.contactSurname.toLowerCase().includes(search) ||
                                    dealer.email ? dealer.email.toLowerCase().includes(search) : null ||
                                    dealer.phone ? dealer.phone.toLowerCase().includes(search) : null ||
                                    dealer.city ? dealer.city.toLowerCase().includes(search) : null ||
                                    dealer.province ? dealer.province.toLowerCase().includes(search) : null
                                ) {
                                    return (
                                        <TableRow key={dealer.id}>
                                            <TableCell>{dealer.dealerName}</TableCell>
                                            <TableCell>{`${dealer.contactName} ${dealer.contactSurname}`}</TableCell>
                                            <TableCell>{dealer.email}</TableCell>
                                            <TableCell>{dealer.phone}</TableCell>
                                            <TableCell>{dealer.city}</TableCell>
                                            <TableCell>{dealer.province}</TableCell>
                                            <TableCell align="right">
                                                <ButtonGroup>
                                                    <IconButton color="primary">
                                                        <Edit onClick={() => handleEdit(dealer)}/>
                                                    </IconButton>
                                                    <IconButton color="primary">
                                                        <CreditCard onClick={() => openCardDialog(dealer)}/>
                                                    </IconButton>
                                                    <Link to={`/dealer-detail/${dealer.id}`}>
                                                        <IconButton color="primary">
                                                            <Search />
                                                        </IconButton>
                                                    </Link>
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            })
                            :
                            dealers && dealers
                            .slice((page -1) * itemsPerPage, page * itemsPerPage)
                            .map((dealer) => {
                                return (
                                    <TableRow key={dealer.id}>
                                        <TableCell>{dealer.dealerName}</TableCell>
                                        <TableCell>{`${dealer.contactName} ${dealer.contactSurname}`}</TableCell>
                                        <TableCell>{dealer.email}</TableCell>
                                        <TableCell>{dealer.phone}</TableCell>
                                        <TableCell>{dealer.city}</TableCell>
                                        <TableCell>{dealer.province}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <IconButton color="primary">
                                                    <Edit onClick={() => handleEdit(dealer)}/>
                                                </IconButton>
                                                <IconButton color="primary">
                                                    <CreditCard onClick={() => openCardDialog(dealer)}/>
                                                </IconButton>
                                                <Link to={`/dealer-detail/${dealer.id}`}>
                                                    <IconButton color="primary">
                                                        <Search />
                                                    </IconButton>
                                                </Link>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(dealers.length / itemsPerPage)}
                page={page}
                onChange={(e, val) => setPage(val)}
                defaultPage={1}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
            />  */}
            <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
                <DialogContent>
                    {widget}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => closeDialog()}>Close</Button>
                </DialogActions>
            </Dialog>

            {
                selectedDealer
                ?
                <EditDealer open={editOpen} closeDialog={() => setEditOpen(false)} dealer={selectedDealer} />
                :
                null
            }
        </>
    )
}
