import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useDb } from '../../contexts/DatabaseContext'
import Select from 'react-select'

export default function SubCreateDealer({ open, closeDialog }) {
    const { userLevel, currentUser } = useAuth()
    const { GetLevelsBelow, subCreateDealer } = useDb()
    const levels = GetLevelsBelow(userLevel)
    const [selectedLevel, setSelectedLevel] = useState(null)

    // Form State
    const [dealerName, setDealerName] = useState('')
    const [street, setStreet] = useState('')
    const [suburb, setSuburb] = useState('')
    const [city, setCity] = useState('')
    const [province, setProvince] = useState('')
    const [country, setCountry] = useState('') 
    const [contactName, setContactName] = useState('')
    const [contactSurname, setContactSurname] = useState('')
    const [contactIdNo, setContactIdNo] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        setError(null)
        try {
            await subCreateDealer({
                dealerName,
                street,
                suburb,
                city,
                province,
                country,
                contactName,
                contactSurname,
                contactIdNo,
                // email,
                phone,
                selectedLevel,
                allocationKey: currentUser.allocationKey,
                allocationArray: currentUser.allocationArray ? currentUser.allocationArray : currentUser.allocationKey.split('-')
            }).then(() => {
                closeDialog()
            })
        }
        catch(err) {
            setError(err)
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} maxWidth={'md'} fullWidth>
            <DialogContent>
                <Select
                    options={levels}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                    placeholder={'Level ...'}
                    onChange={(v) => setSelectedLevel(v.value)}
                />
                <br/>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField label="Dealer Name / Trading Name" variant="outlined" fullWidth onChange={(e) => setDealerName(e.target.value)} required/><br/><br/>
                        <TextField label="Street Address" variant="outlined" fullWidth onChange={(e) => setStreet(e.target.value)} required/><br/><br/>
                        <TextField label="Suburb" variant="outlined" fullWidth onChange={(e) => setSuburb(e.target.value)} required/><br/><br/>
                        <TextField label="City" variant="outlined" fullWidth onChange={(e) => setCity(e.target.value)} required/><br/><br/>
                        <TextField label="Province" variant="outlined" fullWidth onChange={(e) => setProvince(e.target.value)} required/><br/><br/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label="Country" variant="outlined" fullWidth onChange={(e) => setCountry(e.target.value)} required/><br/><br/>
                        <TextField label="Contact Name" variant="outlined" fullWidth onChange={(e) => setContactName(e.target.value)} required/><br/><br/>
                        <TextField label="Contact Surname" variant="outlined" fullWidth onChange={(e) => setContactSurname(e.target.value)} required/><br/><br/>
                        <TextField label="Contact ID Number" variant="outlined" fullWidth onChange={(e) => setContactIdNo(e.target.value)} required/><br/><br/>
                        {/* <TextField type="email" label="Email" variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)}/><br/><br/> */}
                        <TextField type="text" label="Phone Number" variant="outlined" fullWidth onChange={(e) => setPhone(e.target.value)} required/><br/><br/>
                        <span style={{ color: 'red' }}>{error}</span>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} disabled={loading} fullWidth type="submit" variant="contained" color="primary">Submit</Button>
            </DialogActions>
        </Dialog>
    )
}
