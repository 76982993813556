import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import allocateStock from '../../img/allocate-stock.svg'

export default function AllocateStock() {
    return (
        <div>
            <h1>Allocate Stock</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                        <Card>
                            <CardHeader 
                                title={'Allocate Stock'}
                                avatar={<FontAwesomeIcon icon={faTruck}/>}
                            />
                            <CardContent className="sim-card-content">
                                <img src={allocateStock} width="100%" height="100"/>
                            </CardContent>
                            <CardActions>
                                {/* <Button color="primary">Batch</Button> */}
                                <Link to={'/allocate-stock/invoice'}><Button color="primary">Invoice</Button></Link>
                                <Link to={'/allocate-stock/box'}><Button color="primary">Box</Button></Link>
                                <Link to={'/allocate-stock/brick'}><Button color="primary">Brick</Button></Link>
                                {/* <Link to={'/check'}><Button color="primary">Check</Button></Link> */}
                            </CardActions>
                        </Card>
                    </Grid>
            </Grid>
        </div>
    )
}
