import { faBoxes, faBoxOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Card, CardActions, CardHeader, Grid, TextField } from '@material-ui/core'
import { Search, SimCard } from '@material-ui/icons'
import React, { useState } from 'react'
import { networks, selectFieldProps } from '../shared/constants'
import Select from 'react-select'
import { useDb } from '../../contexts/DatabaseContext'
import { useHistory } from 'react-router-dom'
import ResultLink from './ResultLink'

export default function CheckStockR() {
    const { searchStock } = useDb()
    const history = useHistory()
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: {},
        network: null,
        searchId: null
    })

    const handleChange = (e) => {
        setState({
            ...state,
            data: {
                ...state.data,
                type: e.target.name,
                searchVal: e.target.value
            }
        })
    }

    const handleSearch = async (type) => {
        setState({
            loading: true,
            error: null
        })
        try {
            const searchId = await searchStock({
                network: state.network, 
                ...state.data
            })
            setState({
                ...state,
                loading: false,
                error: null,
                searchId
            })
        }
        catch(err) {
            setState({
                loading: false,
                erro: err.message
            })
        }
    }

    return (
        <div>
            <h1>Check Stock</h1>
            <h2>Search By:</h2>
            <Select
                options={networks}
                onChange={(val) => setState({ ...state, network: val.value })}
                {...selectFieldProps}
                className="sim-select"
                placeholder={'Select Network ...'}
            />
            <br/>
            {
                state.network 
                ?
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Card>
                            <CardHeader
                                avatar={<Avatar><SimCard /></Avatar>}
                                title={'SIM'}
                                subheader={<TextField name="sim" variant={'outlined'} fullWidth onChange={handleChange} />}
                            />
                            <CardActions>
                                <Button color="primary" variant="contained" disabled={state.loading} onClick={() => handleSearch('sim')} color={'primary'} startIcon={<Search />}>Search</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Card>
                            <CardHeader
                                avatar={<Avatar><SimCard /></Avatar>}
                                title={'MSISDN'}
                                subheader={<TextField name="msisdn" variant={'outlined'} label="27 format, ie 2712345678" fullWidth onChange={handleChange} />}
                            />
                            <CardActions>
                                <Button color="primary" variant="contained" disabled={state.loading} onClick={() => handleSearch('msisdn')} color={'primary'} startIcon={<Search />}>Search</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                avatar={<Avatar><FontAwesomeIcon icon={faBoxOpen} /></Avatar>}
                                title={'Box'}
                                subheader={<TextField name="box" variant={'outlined'} fullWidth onChange={handleChange}/>}
                            />
                            <CardActions>
                                <Button color="primary" variant="contained" disabled={state.loading} onClick={() => handleSearch('boxes')} color={'primary'} startIcon={<Search />}>Search</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    {
                        state.network !== 'telkom'
                        ?
                        <Grid item xs={4}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar><FontAwesomeIcon icon={faBoxes} /></Avatar>}
                                    title={'Brick'}
                                    subheader={<TextField name="brick" variant={'outlined'} fullWidth onChange={handleChange}/>}
                                />
                                <CardActions>
                                    <Button color="primary" variant="contained" disabled={state.loading} onClick={() => handleSearch('bricks')} color={'primary'} startIcon={<Search />}>Search</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        :
                        null
                    }
                </Grid>
                : 
                null
            }
            {
                state.searchId
                ?
                <ResultLink searchId={state.searchId} />
                :
                null
            }
        </div>
    )
}
