import AdminDashboard from '../admin-dashboard/AdminDashboard'
import AllocateStock from '../allocate-stock/AllocateStock'
import AllocateBox from '../allocate-stock/box/AllocateBox'
import AllocateBrick from '../allocate-stock/brick/AllocateBrick'
import CheckStock from '../allocate-stock/check/CheckStock'
import AllocateInvoice from '../allocate-stock/invoice/AllocateInvoice'
import CheckStockR from '../check-stock/CheckStockR'
import SearchResults from '../check-stock/SearchResults'
import AllUsers from '../dealercomps/reports/AllUsers'
import DealerEfficiency from '../dealercomps/reports/DealerEfficiency'
import DrilldownSims from '../dealercomps/reports/DrilldownSims'
import ImportStock from '../import-stock/ImportStock'
import ImportMtn from '../import-stock/mtn/ImportMtn'
import ImportTelkom from '../import-stock/telkom/ImportTelkom'
import ImportVodacom from '../import-stock/vodacom/ImportVodacom'
import IwanConsole from '../iwan/IwanConsole'
import RCheckDealerStock from '../manage-dealers/check-stock-r/RCheckDealerStock'
import AgentsEfficiency from '../manage-dealers/dealer-detail-r/AgentsEfficiency'
import DealerAgents from '../manage-dealers/dealer-detail-r/DealerAgents'
import RDealerDetail from '../manage-dealers/dealer-detail-r/RDealerDetail'
import DealerDetail from '../manage-dealers/DealerDetail'
import DealerStock from '../manage-dealers/DealerStock'
import ManageDealers from '../manage-dealers/ManageDealers'
import ImportActivations from '../network-imports/activations/ImportActivations'
import DailyConnectionsImport from '../network-imports/connections/DailyConnectionsImport'
import ImportConnections from '../network-imports/connections/ImportConnections'
import NetworkImports from '../network-imports/NetworkImports'
import OGRImporter from '../network-imports/ogr/OGRImporter'
import NetworkImportVC from '../network-imports/vodacom/NetworkImportVC'
import AllNotifications from '../notifications/AllNotifications'
import AllocationDetail from '../notifications/AllocationDetail'
import ActivationReportAdmin from '../reports/activations/ActivationReportAdmin'
import ActivationReportDetail from '../reports/activations/ActivationReportDetail'
import ActivationReportSummary from '../reports/activations/ActivationReportSummary'
import ActivationsRaw from '../reports/activations/ActivationsRaw'
import AdminDrilldown from '../reports/AdminDrilldown'
import ConnectionReportAdmin from '../reports/connections/ConnectionReportAdmin'
import ConnectionReportDetail from '../reports/connections/ConnectionReportDetail'
import ConnectionReportSummary from '../reports/connections/ConnectionReportSummary'
import JsonDealerReport from '../reports/JsonDealerReport'
import JsonReport from '../reports/JsonReport'
import Reports from '../reports/Reports'
import LevelSettings from '../settings/levels/LevelSettings'
import RicaForm from '../settings/rica/RicaForm'
import RicaSettings from '../settings/rica/RicaSettings'
import Settings from '../settings/Settings'
import UserSettings from '../settings/users/UserSettings'
import SimDetail from '../sim-results/SimDetail'
import PrivateRoute from './PrivateRoute'

export default function Routes() {
    return (
        <>
          <PrivateRoute exact path='/' component={AdminDashboard} />  
          <PrivateRoute exact path='/allocate-stock' component={AllocateStock} />  
          <PrivateRoute exact path='/import-stock' component={ImportStock} />  
          <PrivateRoute path='/import-stock/vodacom' component={ImportVodacom} />  
          <PrivateRoute path='/import-stock/mtn' component={ImportMtn} />
          <PrivateRoute path='/import-stock/telkom' component={ImportTelkom} />
          <PrivateRoute exact path='/manage-dealers' component={ManageDealers} />  
          <PrivateRoute path='/manage-dealers/stock/:id' component={DealerStock} />  
          <PrivateRoute exact path='/reports' component={Reports} />  
          <PrivateRoute exact path='/network-imports' component={NetworkImports} />  
          <PrivateRoute path='/network-imports/vodacom' component={NetworkImportVC} />  
          <PrivateRoute path='/allocate-stock/box' component={AllocateBox} />  
          <PrivateRoute path='/import-reports/activations' component={ImportActivations} />  
          <PrivateRoute path='/import-reports/connections' component={ImportConnections} />  
          <PrivateRoute path='/import-reports/daily-connections' component={DailyConnectionsImport} />  
          <PrivateRoute path='/reports/connections' component={ConnectionReportAdmin} />
          <PrivateRoute path='/reports/connections-detail/:requestid' component={ConnectionReportDetail} />  
          <PrivateRoute path='/reports/activations' component={ActivationReportAdmin} />
          <PrivateRoute path='/reports/activations-detail/:requestid' component={ActivationReportDetail} />  
          <PrivateRoute path='/allocate-stock/brick' component={AllocateBrick} />  
          <PrivateRoute path='/allocate-stock/invoice' component={AllocateInvoice} />  
          <PrivateRoute exact path='/settings' component={Settings} />
          <PrivateRoute path='/settings/levels' component={LevelSettings} />  
          <PrivateRoute path='/settings/users' component={UserSettings} />
          <PrivateRoute path='/settings/rica' component={RicaSettings} />
          <PrivateRoute path='/check' component={CheckStock} />
          <PrivateRoute path='/rica' component={RicaForm} />
          <PrivateRoute path='/efficiency/:dealerId/:rank' component={DealerEfficiency} />
          <PrivateRoute path='/import-ogr' component={OGRImporter} />
          <PrivateRoute path='/drilldown-sims/:network/:dealerId/:year/:month/:state/:type' component={DrilldownSims} />
          <PrivateRoute path='/sim/:network/:iccid' component={SimDetail} />
          <PrivateRoute exact path='/dd-report-admin/:type/:rank/:dealerId/:year/:month/:network' component={AdminDrilldown}/>
          <PrivateRoute exact path='/all-users' component={AllUsers}/>
          <PrivateRoute exact path='/activations-raw/:network/:year/:month' component={ActivationsRaw}/>
          <PrivateRoute exact path='/iwan-console' component={IwanConsole}/>
          <PrivateRoute exact path='/all-notifications' component={AllNotifications}/>
          <PrivateRoute exact path='/allocation-detail/:allocationId' component={AllocationDetail}/>
          <PrivateRoute exact path='/json-report/:network/:type/:reportId' component={JsonReport}/>
          <PrivateRoute exact path='/json-dealer-report/:network/:type/:reportId/:dealerId' component={JsonDealerReport}/>
          <PrivateRoute exact path='/json-report/:network/:type/:reportId/:month/:year' component={JsonReport}/>
          <PrivateRoute exact path='/json-dealer-report/:network/:type/:reportId/:dealerId/:month/:year' component={JsonDealerReport}/>

          {/* Notifications */}
          <PrivateRoute exact path='/all-notifications' component={AllNotifications}/>
          <PrivateRoute exact path='/allocation-detail/:allocationId' component={AllocationDetail}/>


          {/* New BQ Method */}
          <PrivateRoute exact path='/activation-report-summary' component={ActivationReportSummary}/>
          <PrivateRoute exact path='/connection-report-summary' component={ConnectionReportSummary}/>
          <PrivateRoute path='/check-dealer-stock/:dealerId' component={RCheckDealerStock}/>
          <PrivateRoute path='/dealer-detail/:dealerId' component={RDealerDetail}/>
          <PrivateRoute path='/check-stock/' component={CheckStockR}/>
          <PrivateRoute path='/check-stock-result/:id' component={SearchResults}/>

          {/* Agents Efficiency */}
          <PrivateRoute path='/agents-efficiency/:parentDealer/:network' component={AgentsEfficiency}/>
          <PrivateRoute path='/dealer-agents/:dealerId' component={DealerAgents}/>


        </>
    )
}