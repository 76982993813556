import { Button, Card, CardContent, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import JsonReportDetail from './JsonReportDetail'
import Select from 'react-select'
import { selectFieldProps } from '../shared/constants'

export default function JsonReport() {
    const { network, type, reportId } = useParams()
    const history = useHistory()
    const { getJsonReport, getDealersOfRank } = useDb()

    const ranks = [
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
    ]

    const [report, setReport] = useState()
    const [dealers, setDealers] = useState()
    const [total, setTotal] = useState()
    const [rank, setRank] = useState(2)
    const [loading, setLoading] = useState(false)

    const firstLoad = async () => {
        const jsonUrl = await getJsonReport(network, type, reportId)
        const r = await fetch(jsonUrl).then(res => {
            
            return res.json()
        })
        const dealerArray = await getDealersOfRank(rank)
        // De-String the allocation Key
        r && r.map(sim => {
            if(sim.allocationKey) {

                const uidArray = Array.isArray(sim.allocationKey) ? sim.allocationKey.split("-") : sim.allocationKey
                sim.uidArray = uidArray
            }
        })

        dealerArray && dealerArray.map(d => {
            let counter = 0
            r && r.map(sim => {
                if(sim.uidArray && sim.uidArray.includes(d.id)) {
                    counter++
                }
            })
            d.count = counter
        })
        
        setDealers(dealerArray)
        setTotal(r.length)
        setReport(r)
    }

    const reloadDealers = async (newRank) => {
        const dealerArray = await getDealersOfRank(newRank)
        // De-String the allocation Key
        report && report.map(sim => {
            if(sim.allocationKey) {
                const uidArray = sim.allocationKey.split("-")
                sim.uidArray = uidArray
            }
        })

        dealerArray && dealerArray.map(d => {
            let counter = 0
            report && report.map(sim => {
                if(sim.uidArray && sim.uidArray.includes(d.id)) {
                    counter++
                }
            })
            d.count = counter
        })
        setDealers(dealerArray)
    }

    const handleRankChange = async (v) => {
        setRank(v.value)
        setLoading(true)
        await reloadDealers(v.value)
        setLoading(false)
    }

    if(!report) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <h1>{type} Report</h1>
            <div className="back-link">
                <Button
                    startIcon={<ArrowBack />}
                    variant="contained"
                    color="primary"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
                &nbsp;<br/><br/>
                <Select
                    {...selectFieldProps}
                    options={ranks}
                    placeholder="Change Level ..."
                    onChange={handleRankChange}
                />
            </div>
            <Card>
                <CardContent>
                    <Typography variant="h6">
                        Total Connections for Company: {total}
                    </Typography>
                    {
                        !loading && dealers
                        ?
                        <JsonReportDetail
                            dealers={dealers}
                            reportId={reportId}
                            network={network}
                            type={type}
                        />
                        :
                        <CircularProgress />
                    }
                    
                </CardContent>
            </Card>
        </div>
    )
}
