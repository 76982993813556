import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useAuth } from '../../../../contexts/AuthContext'
import { useDb } from '../../../../contexts/DatabaseContext'
import ReportTree from '../../../reports/ReportTree'

export default function DealerConnectionSummary() {
    const { GetUserSubDealerConnections } = useDb()
    const { currentUser } = useAuth()
    const { reportId, total } = useParams()
    const report = GetUserSubDealerConnections(reportId)
    const history = useHistory()
    const [compState, setCompState] = useState({})

    let rows = []
    report.map(r => {
        return rows.push({
            ...r,
            id: r.dealerID
        })
    })

    function list_to_tree(list) {
        var map = {}, node, roots = [], i;
        for (i = 0; i < list.length; i += 1) {
          map[list[i].id] = i; // initialize the map
          list[i].children = []; // initialize the children
        }
        for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.parentDealer !== currentUser.uid) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentDealer]].children.push(node);
            // if(node.level !== '2') {
            //     list[map[node.parentDealer]].children.push(node);
            // }
          } else {
            roots.push(node);
          }
        }
        return roots;
    }
    
    const tree = list_to_tree(rows)

    return (
        <div>
            <Button onClick={() => history.goBack()} color="primary" variant="contained" startIcon={<ArrowBack />}>Back</Button>
            <br/><br/>
            <ReportTree report={tree} type={'Connections'} total={total} />
        </div>
    )
}
