import { Avatar, Card, CardContent, CardHeader, Container } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import React, { useState } from 'react'
import Select from 'react-select'
import { networks, selectFieldProps } from '../../shared/constants'
import MtnDailyImport from './MtnDailyImport'

export default function DailyConnectionsImport() {
    const [network, setNetwork] = useState()
    
    return (
        <div>
            <Container>
                <h1>Daily Connections Imports</h1>
                <Card>
                    <CardHeader
                        title="Warning"
                        avatar={
                            <Avatar
                                style={{ backgroundColor: '#f48c09'}}
                            >
                                <Warning />
                            </Avatar>
                        }
                    />
                    <CardContent>
                        This import is for <b>Daily Connections</b> only. This is not to be used for Final Monthly Connections.<br/>
                        To final connections, please go to the <b>Final Monthly Connections Import</b> screen.
                    </CardContent>
                </Card>
                <br/>
                <Select
                    options={networks}
                    {...selectFieldProps}
                    placeholder="Select Network ..."
                    onChange={(val) => setNetwork(val.value)}
                    className="sim-select"
                />
                {
                    network === 'mtn'
                    ?
                    <MtnDailyImport />
                    :
                    null
                }
            </Container>
        </div>
    )
}
