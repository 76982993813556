import React, { useContext, useState, useEffect } from 'react'
import { auth, db, authFunc } from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}



export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [userLevel, setUserLevel] = useState()
    const [loading, setLoading] = useState(true)

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    async function logout() {
        return await auth.signOut().then(() => {
            setCurrentUser(null)
        })
    }

    function forgotPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function loginWithPhone(phoneNo, verifier) {
        return auth.signInWithPhoneNumber(phoneNo, verifier)
    }

    async function getUserDoc(uid) {
        return db.collection('users')
        .doc(uid)
        .get().then(doc => {
            return doc.data()
        })
    }

    async function getAllocationKey(uid) {
        return db.collection('dealers')
        .doc(uid)
        .get().then(doc => {
            if(Array.isArray(doc.data().allocationKey)) {
                return doc.data().allocationKey.join('-')
            }
            else return doc.data().allocationKey ? doc.data().allocationKey : uid
        })
    }

    async function publicDealerRequestCheck(number) {
        return await db.collection('companies').doc('kNa6fz4vZad6033vZZtz')
        .collection('dealerRequests')
        .where('phone', '==', number)
        .get().then(snap => {
            if(snap.empty) {
                throw({
                    message: 'This phone number has not been added.'
                })
            }
        })
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            if(user) {
                
                const userDoc = await getUserDoc(user.uid)
                const level = userDoc.rank
                const allocationKey = await getAllocationKey(user.uid)
                setUserLevel(level)
                setCurrentUser({...user, level, ...userDoc, allocationKey })
            }
            setLoading(false)
        })

        return unsubscribe
    }, [])


    const value = {
        currentUser,
        login,
        logout,
        userLevel,
        forgotPassword,
        loginWithPhone,
        publicDealerRequestCheck
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}
