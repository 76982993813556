import React, { useEffect, useState } from 'react'
import { useDb } from '../../../../contexts/DatabaseContext';
import { barChartOptions } from '../../../shared/constants';
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid } from '@material-ui/core'
import Chart from 'react-apexcharts'

export default function RICATable({month, dealer}) {

  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [labels, setlabels] = useState()
  const { GetRicaLog } = useDb()
  const date = new Date()
  
  const getDaysInMonth = function(month) {
    let year = date.getFullYear()
    return new Date(year, month, 0).getDate();
  };
  
  useEffect(async () => {
    setLoading(true)
    const logs = await GetRicaLog(dealer)
    let newLabels = []
    if(month === date.getMonth() +1) {
      for (let i = 1; i <= date.getDate(); i++) {
        newLabels.push(i)      
      }
    } else {
      for (let i = 1; i <= getDaysInMonth(month); i++) {
        newLabels.push(i)
      }
    }
    
    setlabels(newLabels)

    let newData = []
    for (let i = 0; i < newLabels.length; i++) {
      let counter = 0
      const l = newLabels[i];
      for (let j = 0; j < logs.length; j++) {
        const log = logs[j];
        if (l === log.timestamp.toDate().getDate() && log.timestamp.toDate().getMonth() + 1 === month) {
          counter++
        }
      }
      newData.push(counter)
    }
    setData(newData)
    setLoading(false)
  }, [dealer, month])

  const series = [{
    name: 'Rica\'s',
      data: data
  }]

  return (
    <div>
      <br />
      <Card elevation={4}>
        <CardContent>
          {
            loading
            ?
            <CircularProgress />
            :
            <Chart type='bar' height={300} options={{
              ...barChartOptions,
              xaxis: {
                categories: labels
              }
              }} series={series}/>
          }
        </CardContent>
      </Card>
    </div>
  )
}
