import { Button, CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, Container } from '@material-ui/core'
import { ArrowBack, Search } from '@material-ui/icons'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDb } from '../../contexts/DatabaseContext'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox } from '@fortawesome/free-solid-svg-icons'

export default function AllocationDetail() {
    const { allocationId } = useParams()
    const history = useHistory()
    const { getSingleAllocation, getDealer } = useDb()

    const [allocation, setAllocation] = useState(false)
    const [dealer, setDealer] = useState(null)

    const firstLoad = async () => {
        const a = await getSingleAllocation(allocationId)
        const d = await getDealer(a.user)
        setDealer(d)
        setAllocation(a)
    }

    if(!allocation) {
        firstLoad()
        return <CircularProgress />
    }
    else return (
        <div>
            <div className="back-link">
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </div>
            <Typography>Dealer: {dealer.dealerName}</Typography>
            <Typography>Network: {allocation.network}</Typography>
            <Typography>Allocation Timestamp: {moment(allocation.timestamp.toDate()).format('DD/MM/YYYY HH:MM')}</Typography>
            <br/>
            <Container>
                <Typography>Boxes / Bricks:</Typography>
                <List>
                    {
                        allocation.records && allocation.records.map((b, i) => (
                            <ListItem key={i} divider>
                                <ListItemIcon><FontAwesomeIcon icon={faBox} /></ListItemIcon>
                                <ListItemText>{b.box}</ListItemText>
                            </ListItem>
                        ))
                    }
                </List>
            </Container>
        </div>
    )
}
