import React, { useState } from 'react'
import Select from 'react-select'
import SimCsvImporter from '../../shared/SimCsvImporter'
import { months, years } from '../../shared/constants'
import MtnActivationsSchema from '../mtn/MtnActivationsSchema'
import VodacomActivationsSchema from '../vodacom/VodacomActivationsSchema'
import TelkomConnectionsSchema from '../telkom/TelkomConectionsSchema'

export default function ImportActivations() {
    const [network, setNetwork] = useState(null)
    const [simId, setSimId] = useState(null)
    const [year, setYear] = useState(null)
    const [month, setMonth] = useState(null)
    const [msisdnId, setMsisdnId] = useState(null)
    const [schema, setSchema] = useState(null)

    const handleNetworkChange = (network) => {
        if(network === 'vodacom') {
            setSimId('serial_number')
            setMsisdnId('MSISDN')
            setSchema(<VodacomActivationsSchema />)
        }
        else if(network === 'mtn') {
            setSimId('kit_sim')
            setMsisdnId('msisdn')
            setSchema(<MtnActivationsSchema />)
        }
        else if(network === 'cellC') {
        }
        else if(network === 'telkom') {
            setSimId('sim')
            setMsisdnId('msisdn')
            setSchema(<TelkomConnectionsSchema />)
        }
        setNetwork(network)
    }


    const networks = [
        { value: 'mtn', label: 'MTN'},
        { value: 'vodacom', label: 'Vodacom'},
        { value: 'telkom', label: 'Telkom'},
        
    ];

    return (
        <div>
            <h1>Import Activations</h1>
            <Select
                options={networks}
                onChange={(val) => handleNetworkChange(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Network'}
            />
            <br/>
            <Select
                options={years}
                onChange={(val) => setYear(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Year'}
            />
            <br/>
            <Select
                options={months}
                onChange={(val) => setMonth(val.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                placeholder={'Select a Month'}
            />
            {
                network && year && month ?
                    <SimCsvImporter network={network} simId={simId} month={month} year={year} msisdnId={msisdnId} fileType='activations' schema={schema}/>
                : null
            }
        </div>
    )
}
